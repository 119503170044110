import React from 'react';
import useField from 'bambino-form/useField';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//
import Row from '@lushdigital/ui/row';
import Error from '@lushdigital/ui/error';
import ImageUploader from '@lushdigital/ui/imageUploader';
import ImageManager from '@lushdigital/ui/imageManager';

// *********** Upload file to Cloudinary ******************** //
function uploadFile(file, setImage, setLoading) {
  const url = `https://api.cloudinary.com/v1_1/ddal9rb1d/upload`;
  const xhr = new XMLHttpRequest();
  const fd = new FormData();
  xhr.open('POST', url, true);
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

  // start loading
  setLoading(true);

  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      // File uploaded successfully
      const response = JSON.parse(xhr.responseText);
      const tokens = response.secure_url.split('/');
      tokens.splice(-4, 0, 'q_100');
      setLoading(false);
      setImage(tokens.join('/'));
    }
  };

  fd.append('upload_preset', 'connect-poc');
  fd.append('tags', 'connect-poc');
  fd.append('file', file);
  xhr.send(fd);
}

/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
// Requred to support getting ref
class UploadMiddleMan extends React.Component {
  render() {
    const {
      image,
      loading,
      handleUpload,
      handleSave,
      handleDelete,
      isGif,
      setAlt,
      setPan,
      setScale,
      alt,
      pan,
      scale,
      focusOnRender
    } = this.props;
    return (
      <Row padding="20px 0">
        {!image && <ImageUploader loading={loading} onUpload={handleUpload} />}
        {image && (
          <ImageManager
            image={image}
            loading={loading}
            size="post"
            sizes={[
              {
                key: 'post',
                label: 'P',
                width: 1000,
                height: 560
              }
            ]}
            onSave={handleSave}
            onDelete={handleDelete}
            onAltChange={val => setAlt(val)}
            alt={alt}
            enablePan={!isGif}
            onPanChange={val => setPan(val)}
            pan={pan}
            enableScale={!isGif}
            onScaleChange={val => setScale(val)}
            scale={scale}
            focusOnRender={focusOnRender}
            enableSize={false}
          />
        )}
      </Row>
    );
  }
}
/* eslint-enable react/prop-types */
/* eslint-enable react/prefer-stateless-function */

// exported component
const Upload = ({ imageValue, altValue }) => {
  const { t } = useTranslation();
  const ref = React.useRef(null);

  const { onChange: altOnChange } = useField({
    name: 'image_alt',
    ref,
    value: altValue,
    path: 'props.alt'
  });

  const { onChange: imageOnChange, error: imageError } = useField({
    name: 'image_url',
    ref,
    value: imageValue,
    path: 'props.image'
  });

  // image
  const [image, setImage] = React.useState(imageValue);
  const [imageBlob, setImageBlob] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isGif, setIsGif] = React.useState(false);

  // image manageer
  const [alt, setAlt] = React.useState(altValue);
  const [scale, setScale] = React.useState(0);
  const [pan, setPan] = React.useState({ x: 0, y: 0 });

  // fun-ctions
  const handleAlt = val => {
    setAlt(val);
    altOnChange({ target: { name: 'image_alt', value: val } });
  };
  const handleUpload = e => {
    setIsGif(e.blob.type.includes('gif'));
    setImage(e.base64);
    setImageBlob(e.blob);
  };
  const handleSettingImage = val => {
    setImage(val);
    imageOnChange({ target: { name: 'image_url', value: val } });
  };
  const handleSave = e => {
    if (isGif) {
      uploadFile(imageBlob, handleSettingImage, setLoading);
    } else {
      uploadFile(e.blob, handleSettingImage, setLoading);
    }
  };
  const handleDelete = () => {
    setImage(null);
  };

  return (
    <>
      <UploadMiddleMan
        ref={ref}
        image={image}
        loading={loading}
        handleUpload={handleUpload}
        handleSave={handleSave}
        handleDelete={handleDelete}
        isGif={isGif}
        setAlt={handleAlt}
        setPan={setPan}
        setScale={setScale}
        alt={alt}
        pan={pan}
        scale={scale}
        focusOnRender={!imageValue}
      />
      {imageError && <Error margin="-10px 0 0">{t(imageError)}</Error>}
    </>
  );
};

Upload.defaultProps = {
  imageValue: '',
  altValue: ''
};

Upload.propTypes = {
  imageValue: PropTypes.string,
  altValue: PropTypes.string
};

export default Upload;
