// base url
const BASE = '/:language';

// exported
const routes = {
  HOME: {
    path: BASE
  },
  FEED: {
    path: BASE
  },
  STORY: {
    path: `${BASE}/discussions/:id`
  },

  // profile routes
  PROFILE: {
    path: `${BASE}/profile`
  },

  USER_PROFILE: {
    path: `${BASE}/profile/:profile_id`
  },

  // discussion routes
  DISCUSSIONS: {
    path: `${BASE}/discussions`
  },
  DISCUSSION: {
    path: `${BASE}/discussions/:id`
  },

  //
  MY_DISCUSSIONS: {
    path: `${BASE}/my-discussions`
  },

  // community routes
  COMMUNITY: {
    path: `${BASE}/community`
  },

  // misc
  COMMUNITY_GUIDELINES: {
    path: `${BASE}/community-guidelines`
  },
  PRIVACY_POLICY: {
    path: `${BASE}/privacy-policy`
  }
};

export default routes;
