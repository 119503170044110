/* eslint-disable */
// https://github.com/prettier/prettier/issues/5961
//
import styled from 'styled-components';
import P from '@lushdigital/ui/p';
import Button from '@lushdigital/ui/button';
import { buttonReset } from '@lushdigital/ui/helpers/cssHelpers';
import { Link } from 'react-router-dom';


// positions element left or right
export const Wrapper = styled.div`
  display: block;

  @media (min-width: 680px) {
    display: flex;
    justify-content: flex-start;

    &:nth-child(even) {
      justify-content: flex-end;
    }
  }
`;

export const EditButton = styled(Button)`
  position: absolute;
  z-index: 10;

  ${Wrapper}:nth-child(odd) & {
    top: 52px;
    right: calc(3vw + 10px);
  }

  ${Wrapper}:first-child & {
    top: 10px;
  }

  ${Wrapper}:nth-child(even) & {
    top: 52px;
    right: 10px;
  }
`;

// converts into an anchor if a link is supplied
export const Content = styled.div`
  text-decoration: none;
  display: block;
  padding-top: 42px;
  position: relative;

  @media (min-width: 680px) {
    ${Wrapper}:first-child & {
      padding-top: 0;
    }

    ${Wrapper}:nth-child(odd) & {
      width: calc(50% + 1px);
      padding-right: 3vw;
      border-right: 1px solid #d8d8d8;
    }

    ${Wrapper}:nth-child(even) & {
      width: 50%;
      padding-left: 3vw;
      border-left: 1px solid #d8d8d8;
      justify-content: flex-end;
    }
  }

  @media (min-width: 1440px) {
    ${Wrapper}:nth-child(odd) & {
      padding-right: 40px;
    }

    ${Wrapper}:nth-child(even) & {
      padding-left: 40px;
    }
  }
`;

// converts into an anchor if a link is supplied
export const MockLinkDiv = styled.div`
  text-decoration: none;
  display: block;
`

// containers data + title + caption
export const Body = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid #d8d8d8;
`;

// render for date stamp
export const Date = styled(P)`
  position: relative;
`;

// render for positioning image
export const ImageWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
  padding: 0 !important;

  ${({ isInLink }) =>
    isInLink
      ? `
    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    &:before {
      transition: all 0.3s;
      opacity: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0) 25%);
      z-index: 2;
    }

    &:after {
      transition: all 0.3s;
      opacity: 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.4) 75%);
      z-index: 2;
    }

    @media (hover: hover) {
      *:hover > & {
        &:before {
          opacity: 1;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  `
      : null}
`;

// button to open discussion
export const RepliesButton = styled(Link)`
  ${buttonReset}
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: #000;
`;

// render for title
export const Title = styled(P)`
  font-weight: 700;
  padding-left: 10px;
`;

export const StaffFlag = styled.span`
  display: inline-block;
  margin-left: 10px;
  border: 1px solid #00a44c;
  border-radius: 15px;
  font-weight: 700;
  font-size: 11px;
  color: #00a44c;
  letter-spacing: 0.5px;
  padding: 4px 10px;
`;