import React from 'react';
import useField from 'bambino-form/useField';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// local
import Row from '@lushdigital/ui/row';
import Error from '@lushdigital/ui/error';

//
import Toggle from '../../toggle';

// styled
const Wrapper = styled.label`
  display: block;

  ${({ hidden }) =>
    hidden
      ? `
    visibility: hidden;
    height: 1px;
    width: 1px;
    overflow: hidden;
  `
      : `
    width: 100%;
  `};
`;

/* eslint-disable react/prefer-stateless-function */
// Requred to support getting ref
class ToggleMiddleMan extends React.Component {
  render() {
    return <Toggle {...this.props} />;
  }
}
/* eslint-enable react/prefer-stateless-function */

const ToggleField = ({
  name,
  label,
  multiline,
  schema,
  subtext,
  value,
  hidden,
  suffix,
  customChangeHandler,
  ...rest
}) => {
  const ref = React.useRef(null);
  const { t } = useTranslation();
  const [isActive, setIsActive] = React.useState(value);

  // sanitize
  const { error, fieldName, defaultValue, onChange, ...bag } = useField({
    name,
    ref,
    schema,
    value,
    path: 'props.isActive'
  });

  const toggleProps = {
    ...rest,
    ...bag,
    onChange: val => {
      setIsActive(val);
      onChange({ target: { name, value: val } });
    },
    fullWidth: true,
    defaultValue,
    ref
  };

  return (
    <Wrapper hidden={hidden} htmlFor={toggleProps.name}>
      <Row flexWrap="nowrap" alignItems="stretch">
        <ToggleMiddleMan isActive={isActive} label={label} {...toggleProps} />
      </Row>
      {error && <Error margin="10px 0 0">{t(error)}</Error>}
    </Wrapper>
  );
};

ToggleField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  subtext: PropTypes.string,
  schema: PropTypes.any,
  hidden: PropTypes.bool,
  multiline: PropTypes.bool,
  suffix: PropTypes.string,
  customChangeHandler: PropTypes.func,
  value: PropTypes.bool
};

ToggleField.defaultProps = {
  subtext: '',
  placeholder: '',
  schema: null,
  hidden: false,
  multiline: false,
  value: false,
  suffix: null,
  customChangeHandler: _ => _
};

ToggleField.displayName = 'ToggleField';

export default ToggleField;
