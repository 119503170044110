import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Icon from '@lushdigital/icons';
import { buttonReset } from '@lushdigital/ui/helpers/cssHelpers';
import fluidProp from '@lushdigital/ui/helpers/fluidProp';

// ui
import Container from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import H3 from '@lushdigital/ui/h3';
import P from '@lushdigital/ui/p';

//
import Avatar from '../../components/avatar';
import Username from '../../components/username';
import { fromNow } from '../../helpers';

// styled
const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 800px) {
    width: 50%;
  }

  ${fluidProp({
    prop: 'padding',
    min: { value: 20 },
    max: { value: 50 }
  })}
`;

const PostRow = styled(Row)`
  @media (min-width: 800px) {
    align-items: flex-end;
  }
`;

const Limit = styled.div`
  width: calc(100% - 60px);

  @media (min-width: 620px) {
    width: 100%;
  }
`;

const Box = styled.div`
  position: relative;
  max-width: 520px;
  width: 100%;
  padding-left: 50px;
  padding-bottom: 30px;
  opacity: ${({ isFocused }) => (isFocused ? '1' : '0.6')};

  &:not(:last-child) {
    margin-bottom: 30px;
    border-bottom: 1px solid #eaeaea;
  }
`;

const Group = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: -50px;
    width: 3px;
    height: 90%;
    background: ${({ isSelected }) => (isSelected ? '#000' : '#fff')};
  }
`;

const User = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const RepliesButton = styled.button`
  ${buttonReset}
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const Title = styled(P)`
  font-weight: 700;
  padding-left: 10px;
`;

const StaffFlag = styled.span`
  display: inline-block;
  margin-left: 10px;
  border: 1px solid #00a44c;
  border-radius: 15px;
  font-weight: 700;
  font-size: 11px;
  color: #00a44c;
  letter-spacing: 0.5px;
  padding: 0 10px;
`;

const IconWrapper = styled.div`
  color: #fff;
  background: #000;
  padding: 0.5em;
  font-size: 40px;
  border-radius: 100%;
  line-height: 0;
  margin-bottom: 0.5em;
`;

// exported component
const ForumList = ({ forums, selectPost, selectedPost }) => {
  const { t, i18n } = useTranslation();

  if (forums.length === 0) {
    return (
      <Container padding="15vmin 10px">
        <Row padding="40px 0">
          <Column alignItems="center">
            <IconWrapper>
              <Icon icon="comments" size="0.8em" />
            </IconWrapper>
            <H3 align="center" margin="0 0 10px">
              {t('NO_FORUM_POSTS_POSTED_YET')}
            </H3>
          </Column>
        </Row>
      </Container>
    );
  }

  return (
    <PostContainer padding="50px 20px">
      <PostRow flexDirection="column" alignItems="flex-start">
        {forums.map(post => (
          <Box
            key={post.id}
            isSelected={selectedPost === post.id}
            isFocused={selectedPost === post.id || selectedPost === null}
          >
            <User>
              <Avatar textSize="30px" profileId={post.user_id} />
            </User>
            <P lineHeight="20px" textSize="14px" margin="5px 0 0">
              <Username profileId={post.user_id} />, {fromNow(post.created_at, i18n.language)}
              {post.staff_only && <StaffFlag>{t('STAFF_ONLY')}</StaffFlag>}
            </P>
            <Group isSelected={selectedPost === post.id}>
              <Limit>
                <H3 margin="10px 0">{post.title}</H3>
                <P lineHeight="20px" textSize="14px">
                  {post.body}
                </P>
              </Limit>
              <RepliesButton onClick={() => selectPost(post.id)}>
                <Icon icon="comments" />
                <Title>
                  {t('REPLIES_COUNT', {
                    count: post.reply_count || 0
                  })}
                </Title>
              </RepliesButton>
            </Group>
          </Box>
        ))}
      </PostRow>
    </PostContainer>
  );
};

ForumList.propTypes = {
  forums: PropTypes.array.isRequired,
  selectedPost: PropTypes.string.isRequired,
  selectPost: PropTypes.func.isRequired
};

export default ForumList;
