import React from 'react';
import PropTypes from 'prop-types';
import { H3, P, Image } from '@lushdigital/ui';
import { useTranslation } from 'react-i18next';

import Icon from '@lushdigital/icons';
import { AuthWrapper } from '@lushdigital/auth';
import GROUPS from '../../../config/config.groups.json';

// styled
import {
  Wrapper,
  Content,
  Date,
  MockLinkDiv,
  ImageWrapper,
  Body,
  RepliesButton,
  Title,
  EditButton,
  StaffFlag
} from './forum-feed.post.styles';

//
import routes from '../../../routes';
import { fromNow } from '../../../helpers';

//
const Post = ({ setPost, id, title, caption, date, imageUrl, link, internal, imageAlt, replyCount, staffOnly }) => {
  // used to select the correct dayjs local
  const { t, i18n } = useTranslation();

  // this is required to handle if the wrapping element needs to change to be a link, anchor or div
  let Render = MockLinkDiv;
  // props hold custom spread properties if the element is a link or anchor
  let props = {};

  if (link) {
    Render = MockLinkDiv.withComponent('a');

    if (internal) {
      props = { href: link, target: '_self' };
    } else {
      props = {
        href: link,
        target: '_blank'
      };
    }
  }

  const formatted = fromNow(date, i18n.language);

  let filename = null;

  if (imageUrl.length) {
    const regExp = /(?=(\w+)\.(\w{3,4}$)).+/g;
    const matches = regExp.exec(imageUrl);
    filename = matches[1]; // eslint-disable-line
  }

  return (
    <Wrapper>
      <Content>
        <Body>
          <AuthWrapper authenticated permissions={GROUPS.CORE.ABOVE_CUSTOMER}>
            <EditButton onClick={() => setPost()} padding="1.4em" circle>
              <Icon icon="edit" size="1em" />
            </EditButton>
          </AuthWrapper>
          <Render {...props}>
            {imageUrl && (
              <ImageWrapper isInLink={link}>
                <Image
                  image={{
                    edited: {
                      link: imageUrl,
                      directory: 'connect/posts/',
                      name: filename
                    },
                    cdn: 'cloudinary'
                  }}
                  cdn="https://res.cloudinary.com/ddal9rb1d/image/"
                  crop={imageUrl.includes('.gif') ? 'c_fill' : ''}
                  alt={imageAlt || ''}
                  requireDOM={false}
                  default={{
                    width: 470,
                    height: 262
                  }}
                />
              </ImageWrapper>
            )}
            <Date textSize="14px" lineHeight="1" margin="0 0 10px">
              {formatted}
              {staffOnly && <StaffFlag>{t('STAFF_ONLY')}</StaffFlag>}
            </Date>
            <H3>{title}</H3>
            {caption && <P>{caption}</P>}
          </Render>
          <RepliesButton to={routes.DISCUSSION.path.replace(':language', i18n.language).replace(':id', id)}>
            <Icon icon="comments" size="1.8em" />
            <Title textSize="14px" lineHeight="21px">
              {t('REPLIES_COUNT', {
                count: replyCount || 0
              })}
            </Title>
          </RepliesButton>
        </Body>
      </Content>
    </Wrapper>
  );
};

Post.defaultProps = {
  link: null,
  caption: null
};

Post.propTypes = {
  setPost: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired, // ./community_queries
  title: PropTypes.string.isRequired, // ./community_queries
  caption: PropTypes.string, // ./community_queries
  link: PropTypes.string, // ./community_queries
  internal: PropTypes.bool.isRequired, // ./community_queries
  staffOnly: PropTypes.bool.isRequired, // ./community_queries
  date: PropTypes.number.isRequired, // ./community_queries
  imageUrl: PropTypes.string.isRequired, // ./community_queries
  imageAlt: PropTypes.string.isRequired, // ./community_queries
  replyCount: PropTypes.number.isRequired // ./community_queries
};

export default React.memo(Post);
