// page imports
import Home from '../pages/home';
import Profile from '../pages/profile';
import Login from '../pages/login';
import Community from '../pages/community';
import Discussions from '../pages/discussions';
import Discussion from '../pages/discussion';

import NotFound from '../components/notFound';
import NotAuthorized from '../components/notAuthorized';

// config
import routes from './index';
// import permissions from '../config/config.groups.json';

// base url
const BASE = '/:language';

// exported
const routesConfig = [
  // homepage routes
  {
    exact: true,
    component: Home,
    authenticated: false,
    path: BASE,
    title: 'HOME',
    icon: 'home'
  },
  {
    exact: true,
    component: Discussion,
    authenticated: false,
    path: routes.STORY.path,
    title: 'STORY',
    icon: 'user'
  },

  // profile routes
  {
    exact: true,
    component: Profile,
    authenticated: false,
    path: routes.PROFILE.path,
    title: 'PROFILE',
    icon: 'user'
  },
  {
    exact: true,
    component: Profile,
    authenticated: false,
    path: routes.USER_PROFILE.path,
    title: 'USER_PROFILE',
    icon: 'user'
  },

  // community routes
  {
    exact: true,
    component: Community,
    authenticated: false,
    path: routes.COMMUNITY.path,
    title: 'COMMUNITY',
    icon: 'user'
  },

  // discussions routes
  {
    exact: true,
    component: Discussion,
    authenticated: false,
    path: routes.DISCUSSION.path,
    title: 'DISCUSSION',
    icon: 'user'
  },
  {
    exact: true,
    component: Discussions,
    authenticated: false,
    path: routes.DISCUSSIONS.path,
    title: 'DISCUSSIONS',
    icon: 'user'
  },

  // Supporting routes
  {
    exact: true,
    component: Login,
    authenticated: false,
    notAuthenticated: true,
    path: `${BASE}/login`,
    title: 'Login',
    icon: 'login'
  },
  {
    component: NotAuthorized,
    authenticated: true,
    path: `${BASE}/401`,
    title: '401'
  },
  {
    // Must be last!
    component: NotFound,
    authenticated: false,
    path: `${BASE}/*`,
    title: '404'
  }
];

export default routesConfig;
