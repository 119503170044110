import gql from 'graphql-tag';

//
import { FORUM, REPLY, USER_PREFERENCES } from './apollo.fragment';

//
export const FORUM_CREATE_REPLY = gql`
  mutation forum_reply($forum_id: String!, $body: String!, $official_staff: Boolean) {
    forum_reply(forum_id: $forum_id, body: $body, official_staff: $official_staff) {
      ${REPLY}
    }
  }
`;

export const FORUM_CREATE_POST = gql`
  mutation forum_create(
    $parent_id: Int
    $parent_type: String
    $featured: Boolean
    $staff_only: Boolean
    $authenticated_only: Boolean
    $internal_link: Boolean
    $link: String
    $title: String!
    $body: String
    $image_url: String
    $image_alt: String
    $tag_ids: [String!]
    $reply_per_page: Int
    $reply_paginate_from: String
    $reply_order: String
  ) {
    forum_create(
      parent_id: $parent_id
      parent_type: $parent_type
      featured: $featured
      staff_only: $staff_only
      authenticated_only: $authenticated_only
      link: $link
      title: $title
      body: $body
      tag_ids: $tag_ids
      image_url: $image_url
      image_alt: $image_alt
      internal_link: $internal_link
    ) {
      ${FORUM}
    }
  }
`;

export const FORUM_EDIT_POST = gql`
  mutation forum_edit(
    $id: String!
    $featured: Boolean
    $staff_only: Boolean
    $authenticated_only: Boolean
    $internal_link: Boolean
    $link: String
    $title: String!
    $body: String
    $image_url: String
    $image_alt: String
    $tag_ids: [String!]
    $reply_per_page: Int
    $reply_paginate_from: String
    $reply_order: String
  ) {
    forum_edit(
      id: $id
      featured: $featured
      staff_only: $staff_only
      authenticated_only: $authenticated_only
      link: $link
      title: $title
      body: $body
      tag_ids: $tag_ids
      image_url: $image_url
      image_alt: $image_alt
      internal_link: $internal_link
    ) {
      ${FORUM}
    }
  }
`;

export const UPDATE_USER_PREFERENCES = gql`
  mutation user_preferences_set(
    $username: String
    $first_name: String
    $last_name: String
    $privacy_discussion_metrics: Boolean
    $privacy_reply_metrics: Boolean
    $privacy_community: Boolean
    $onboarding_privacy: Boolean
    $onboarding_feed: Boolean
    $onboarding_first_discussion: Boolean
    $onboarding_dismissed: Boolean
    $password: String
    $is_private: Boolean
  ) {
    user_preferences_set(
      username: $username
      first_name: $first_name
      last_name: $last_name
      privacy_discussion_metrics: $privacy_discussion_metrics
      privacy_reply_metrics: $privacy_reply_metrics
      privacy_community: $privacy_community
      onboarding_privacy: $onboarding_privacy
      onboarding_feed: $onboarding_feed
      onboarding_first_discussion: $onboarding_first_discussion
      onboarding_dismissed: $onboarding_dismissed
      password: $password
      is_private: $is_private
    ) {
      ${USER_PREFERENCES}
    }
  }
`;
