import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// ui
import fluidProp from '@lushdigital/ui/helpers/fluidProp';
import Image from '@lushdigital/ui/image';
import Container from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import H3 from '@lushdigital/ui/h3';
import CTA from '@lushdigital/ui/cta';

const HeroContainer = styled.div`
  background-color: #000;
  padding-left: 10px;
  padding-right: 10px;

  ${fluidProp({
    prop: 'padding-top',
    min: { value: 20 },
    max: { value: 50 }
  })}
  ${fluidProp({
    prop: 'padding-bottom',
    min: { value: 20 },
    max: { value: 50 }
  })}
`;

const HeroRow = styled(Row)`
  border: 2px solid #fff;

  @media (max-width: 680px) {
    flex-direction: column;
  }
`;

const HeroBody = styled(Column)`
  padding: 20px
    ${fluidProp({
      prop: 'padding-left',
      min: { value: 20 },
      max: { value: 40 }
    })};
`;

const Hero = () => {
  const { t } = useTranslation();

  return (
    <HeroContainer>
      <Container>
        <HeroRow alignItems="center">
          <Column padding="0">
            <Image
              image={{
                edited: {
                  link: 'https://res.cloudinary.com/ddal9rb1d/image/upload/v1575365381/connect/hero.jpg',
                  directory: 'connect/',
                  name: 'hero'
                },
                cdn: 'cloudinary'
              }}
              cdn="https://res.cloudinary.com/ddal9rb1d/image/"
              alt="Help create the next Lush Bath Bomb!"
              requireDOM={false}
              default={{
                width: 530,
                height: 290
              }}
            />
          </Column>
          <HeroBody flexBasis="50%" flexGrow="0">
            <H3 color="#fff">Help create the next Lush Bath Bomb!</H3>
            <Row margin="20px 0 0 0">
              <CTA theme="inverse-clear" margin="0 10px 0 0">
                {t('GET_STARTED')}
              </CTA>
            </Row>
          </HeroBody>
        </HeroRow>
      </Container>
    </HeroContainer>
  );
};

export default Hero;
