import React from 'react';
import { Container, Loader } from '@lushdigital/ui';
import { get } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';

//
import Icon from '@lushdigital/icons';
import Button from '@lushdigital/ui/button';
import { AuthWrapper } from '@lushdigital/auth';

// queriers
import { FORUM_LIST } from '../../../apollo/apollo.queries';

// including components
import Error from '../../../apollo/apollo.error';
import Post from './forum-feed.post';
import Loadmore from './forum-feed.loadmore';
import PostCreate from './forum-feed.postCreate';
import GROUPS from '../../../config/config.groups.json';

//
const Wrapper = styled.div`
  min-height: 380px;
  width: 100%;
`;

const CreateButton = styled(Button)`
  position: absolute;
  z-index: 50;
  top: 30px;
  right: 30px;
`;

// exported component
const ForumFeed = () => {
  const [createPost, setCreatePost] = React.useState(false);
  const [editPost, setEditPost] = React.useState(null);

  const { error, data, loading, fetchMore, networkStatus } = useQuery(FORUM_LIST, {
    variables: {
      featured: true
    },
    pollInterval: 30000
  });

  // render
  if (error) {
    return (
      <Error name="SOCIAL_FEED_POSTS" error={error} addAlert={false}>
        {() => <p>There was an error</p>}
      </Error>
    );
  }

  if (loading) {
    return (
      <Container margin="30px auto 0" padding="0">
        <Loader align="center" />
      </Container>
    );
  }

  // break apart data into useful sections
  const posts = get(data, 'forum_list.forums', []);
  const nextPage = get(data, 'forum_list.pagination.next_page', null);

  // if no posts are returned load latest content.
  if (posts === null) {
    return <p>No posts found</p>;
  }

  // return social feed
  return (
    <Wrapper>
      <AuthWrapper authenticated permissions={GROUPS.CORE.ABOVE_CUSTOMER}>
        <CreateButton padding="2em" circle onClick={() => setCreatePost(true)}>
          <Icon icon="plus" />
        </CreateButton>
      </AuthWrapper>
      <Container margin="30px auto" padding="0">
        {posts.map(post => (
          <Post
            setPost={() => {
              setEditPost(post);
              setCreatePost(true);
            }}
            key={post.id}
            id={post.id}
            title={post.title}
            caption={post.body}
            link={post.link}
            internal={post.internal_link || false}
            staffOnly={post.staff_only || false}
            date={post.created_at}
            imageUrl={post.image_url || ''}
            imageAlt={post.image_alt || post.title}
            replyCount={post.reply_count}
            profileId={post.user_id}
          />
        ))}
        <Loadmore loading={loading} next_page={nextPage} networkStatus={networkStatus} fetchMore={fetchMore} />
        {createPost && (
          <PostCreate
            post={editPost}
            onClose={() => {
              setCreatePost(false);
              setEditPost(null);
            }}
          />
        )}
      </Container>
    </Wrapper>
  );
};

ForumFeed.defaultProps = {};

export default ForumFeed;
