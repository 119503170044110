import gql from 'graphql-tag';

export const FORUM_USER_PUBLIC = gql`
  query forum_user_public($id: String!) {
    forum_user_public(id: $id) {
      id
      profile_id
      first_name
      last_name
      profile_image
      type
      country
    }
  }
`;
