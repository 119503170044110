import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '@lushdigital/icons';
import fluidProp from '@lushdigital/ui/helpers/fluidProp';

// helpers
import routes from '../../routes';

// styles
const Bar = styled.div`
  background: #000;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
`;

const LogoLink = styled(NavLink)`
  display: block;
  line-height: 0;
  margin: 0 0 50px;
  position: relative;
  z-index: 5;

  ${fluidProp({
    prop: 'font-size',
    min: { value: 9 },
    max: { value: 11 }
  })}
`;

const TextLink = styled(Link)`
  display: block;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  text-decoration: none;
  transition: opacity 0.3s ease-in-out;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &:hover {
    opacity: 0.6;
  }

  ${fluidProp({
    prop: 'font-size',
    min: { value: 14 },
    max: { value: 17 }
  })}
`;

// exported component
const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <Bar className="main-footer">
      <LogoLink to={routes.HOME.path.replace(':language', i18n.language)}>
        <Icon icon="lush" xSize="9em" ySize="3em" fill="#fff" />
      </LogoLink>
      <TextLink to={routes.COMMUNITY_GUIDELINES.path.replace(':language', i18n.language)}>
        {t('COMMUNITY_GUIDELINES')}
      </TextLink>
      <TextLink to={routes.PRIVACY_POLICY.path.replace(':language', i18n.language)}>{t('PRIVACY_POLICY')}</TextLink>
    </Bar>
  );
};

export default Footer;
