import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import CTA from '@lushdigital/ui/cta';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import AlertsContext from '@lushdigital/ui/alerts/context';
import Alert from '@lushdigital/ui/alerts/alert';
import { Context as AuthContext } from '@lushdigital/auth';

import Error from '../../../apollo/apollo.error';
import { UPDATE_USER_PREFERENCES } from '../../../apollo/apollo.mutations';
import { GET_USER_PREFERENCES } from '../../../apollo/apollo.queries';
import Toggle from '../../../components/toggle';

const PrivacyForm = ({ onSubmit, userPreferences }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const { user } = React.useContext(AuthContext);
  const [publicProfile, setPublicProfile] = React.useState(!userPreferences.is_private);
  const [discussions, setDiscussions] = React.useState(userPreferences.privacy_discussion_metrics);
  const [replies, setReplies] = React.useState(userPreferences.privacy_reply_metrics);
  const [community, setCommunity] = React.useState(userPreferences.privacy_community);
  const { insertAlert } = React.useContext(AlertsContext);

  const [updateProfile, { error }] = useMutation(UPDATE_USER_PREFERENCES, {
    refetchQueries: [{ query: GET_USER_PREFERENCES, variables: { id: user.profile_id } }],
    onError: () => setLoading(false),
    onCompleted: () => {
      setLoading(false);
      insertAlert({
        type: 'success',
        message: `🎉 ${t(`SUCCESSFULLY_UPDATED_PROFILE`)}`,
        time: 2,
        render: alertProps => <Alert key={alertProps.i} {...alertProps} />
      });
      onSubmit();
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    await updateProfile({
      variables: {
        privacy_discussion_metrics: discussions,
        privacy_reply_metrics: replies,
        privacy_community: community,
        is_private: !publicProfile
      }
    });
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}
      <Row padding="0 0 15px 0">
        <Column>
          <Toggle label={t('MAKE_PROFILE_PUBLIC')} isActive={publicProfile} onChange={val => setPublicProfile(val)} />
        </Column>
      </Row>
      <Row padding="0 0 15px 0">
        <Column>
          <Toggle
            label={t('DISPLAY_DISCUSSION_METRICS_IN_PROFILE')}
            isActive={discussions}
            onChange={val => setDiscussions(val)}
          />
        </Column>
      </Row>
      <Row padding="0 0 15px 0">
        <Column>
          <Toggle label={t('DISPLAY_REPLY_METRICS_IN_PROFILE')} isActive={replies} onChange={val => setReplies(val)} />
        </Column>
      </Row>
      <Row padding="0 0 15px 0">
        <Column>
          <Toggle label={t('DISPLAY_COMMUNITY_IN_PROFILE')} isActive={community} onChange={val => setCommunity(val)} />
        </Column>
      </Row>
      <Row padding="20px 0 0 0">
        <Column>
          <CTA loading={loading}>{t('SAVE_CHANGES')}</CTA>
        </Column>
      </Row>
    </form>
  );
};

PrivacyForm.propTypes = {
  onSubmit: PropTypes.func,
  userPreferences: PropTypes.object.isRequired
};

PrivacyForm.defaultProps = {
  onSubmit: _ => _
};

export default PrivacyForm;
