import React from 'react';
import PropTypes from 'prop-types';
import { H3, P, Image } from '@lushdigital/ui';
import { useTranslation } from 'react-i18next';
import Icon from '@lushdigital/icons';
import { useHistory } from 'react-router-dom';

//
import Button from '@lushdigital/ui/button';
import Avatar from '../../components/avatar';
import Username from '../../components/username';

//
import routes from '../../routes';
import { fromNow } from '../../helpers';

// styled
import {
  Page,
  Wrapper,
  Header,
  Main,
  PostBy,
  Content,
  Date,
  ImageWrapper,
  Title,
  ExpanderButton
} from './discussion.MobileDetails.styles';

//
const PostImage = ({ imageUrl, filename, link, imageAlt }) => (
  <div className="image">
    <ImageWrapper isInLink={link}>
      <Image
        image={{
          edited: {
            link: imageUrl,
            directory: 'connect/posts/',
            name: filename
          },
          cdn: 'cloudinary'
          }} // eslint-disable-line
        cdn="https://res.cloudinary.com/ddal9rb1d/image/"
        crop={imageUrl.includes('.gif') ? 'c_fill' : ''}
        alt={imageAlt || ''} // eslint-disable-line
        requireDOM={false}
        default={{
          width: 470,
          height: 262
        }}
      />
    </ImageWrapper>
  </div>
);

PostImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired
};

// exported component
const Post = ({ featured, title, body, date, imageUrl, link, internal, imageAlt, profileId }) => {
  // used to select the correct dayjs local
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [expand, setExpand] = React.useState(false);

  // this is required to handle if the wrapping element needs to change to be a link, anchor or div
  let ContentRender = Content;
  // props hold custom spread properties if the element is a link or anchor
  let props = {};

  if (link) {
    ContentRender = Content.withComponent('a');

    if (internal) {
      props = { href: link, target: '_self' };
    } else {
      props = {
        href: link,
        target: '_blank'
      };
    }
  }

  const formatted = fromNow(date, i18n.language);

  let filename = null;

  if (imageUrl.length) {
    const regExp = /(?=(\w+)\.(\w{3,4}$)).+/g;
    const matches = regExp.exec(imageUrl);
      filename = matches[1]; // eslint-disable-line
  }

  // render
  return (
    <Page>
      <Wrapper>
        {/* Header */}
        <Header>
          <Button
            theme="inverse"
            padding="1em"
            margin="0 10px 0 -1em"
            onClick={() =>
              featured
                ? history.push(routes.HOME.path.replace(':language', i18n.language))
                : history.push(routes.DISCUSSIONS.path.replace(':language', i18n.language))
            }
          >
            <Icon icon="long-arrow-left" />
          </Button>
          {imageUrl.length > 0 && featured && (
            <PostImage imageUrl={imageUrl} imageAlt={imageAlt} filename={filename} link="" />
          )}
          {!featured && (
            <div>
              <Avatar profileId={profileId} />
            </div>
          )}

          <Title>{title}</Title>

          <ExpanderButton
            expand={expand}
            padding="0.6em"
            theme="inverse"
            onClick={() => setExpand(current => !current)}
          >
            <Icon icon="chevron-up" />
          </ExpanderButton>
        </Header>

        {/* Dropdown */}
        <Main expand={expand}>
          {/* Clickable area if link supplied */}
          <ContentRender {...props}>
            {!featured && (
              <PostBy>
                {t('POSTED_BY')} <Username profileId={profileId} />
              </PostBy>
            )}
            {imageUrl.length > 0 && (
              <PostImage imageUrl={imageUrl} imageAlt={imageAlt} filename={filename} link={link} />
            )}
            {featured && (
              <Date textSize="14px" lineHeight="1" margin="0 0 12px">
                {formatted}
              </Date>
            )}

            <H3>{title}</H3>

            {!featured && (
              <Date textSize="14px" lineHeight="1" margin="12px 0 12px">
                {formatted}
              </Date>
            )}
            {body && <P>{body}</P>}
          </ContentRender>
        </Main>
      </Wrapper>
    </Page>
  );
};

Post.defaultProps = {
  link: null,
  body: null
};

Post.propTypes = {
  title: PropTypes.string.isRequired, // ./community_queries
  body: PropTypes.string, // ./community_queries
  link: PropTypes.string, // ./community_queries
  internal: PropTypes.bool.isRequired, // ./community_queries
  date: PropTypes.number.isRequired, // ./community_queries
  imageUrl: PropTypes.string.isRequired, // ./community_queries
  imageAlt: PropTypes.string.isRequired, // ./community_queries
  featured: PropTypes.bool.isRequired, // ./community_queries
  profileId: PropTypes.string.isRequired // ./community_queries
};

export default React.memo(Post);
