import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '@lushdigital/icons';
import fluidProp from '@lushdigital/ui/helpers/fluidProp';
import { RemoveScroll } from 'react-remove-scroll';

// helpers
import routes from '../../routes';

// components
import Navigation from './header.Navigation';
import User from './header.User';

// styles
const Bar = styled.div`
  background: #000;
  min-height: 70px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
`;

const Link = styled(NavLink)`
  display: block;
  line-height: 0;
  margin: 18px 30px;
  position: relative;
  z-index: 5;

  ${fluidProp({
    prop: 'font-size',
    min: { value: 9 },
    max: { value: 11 }
  })}
`;

const Account = styled.div`
  position: relative;
  z-index: 5;
`;

// exported component
const Header = () => {
  const { i18n } = useTranslation();
  const [menu, setMenu] = React.useState(false);

  return (
    <RemoveScroll enabled={menu} forwardProps>
      <Bar className="main-navigation">
        <Link to={routes.HOME.path.replace(':language', i18n.language)}>
          <Icon icon="lush" xSize="9em" ySize="3em" fill="#fff" />
        </Link>

        <Account>
          <User />
        </Account>

        <Navigation menu={menu} setMenu={setMenu} />
      </Bar>
    </RemoveScroll>
  );
};

export default Header;
