import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Context as AuthContext } from '@lushdigital/auth';
import Icon from '@lushdigital/icons';

// ui
import H3 from '@lushdigital/ui/h3';
import P from '@lushdigital/ui/p';
import Button from '@lushdigital/ui/button';
import Loader from '@lushdigital/ui/loader';
import { GET_USER_PREFERENCES } from '../../apollo/apollo.queries';

// styles
const Box = styled.div`
  padding: 20px;
  margin: 20px 10px;
  border: 1px solid #eaeaea;
  width: 100%;
  flex-shrink: 0;

  @media (min-width: 720px) {
    width: 45vw;
    flex-basis: 45vw;
    max-width: 430px;
    margin: 40px 40px 40px 0;
    padding: 30px;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const BoxInner = styled.div`
  overflow: hidden;
  max-height: ${({ expand }) => (expand ? '800px' : '0')};
  transition: 0.3s all;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 30px 0;
`;

const ListItems = styled.li`
  padding: 0;
  margin: 0;

  &:not(:first-child) {
    padding: 20px 0 0;
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const IconContainer = styled.div`
  font-size: 10px;
  padding: 1.5em;
  background: ${({ completed }) => (completed ? '#00A44C;' : '#000;')};
  color: #fff;
  border-radius: 3em;
  display: inline-flex;
  margin-right: 1.5em;
`;

// exported component
const Onboarding = () => {
  const { user } = React.useContext(AuthContext);
  const [expand, setExpand] = React.useState(true);
  const [dismiss, setDismiss] = React.useState(false);
  const { t } = useTranslation();

  const { loading, data } = useQuery(GET_USER_PREFERENCES, { variables: { id: user.profile_id } });

  const privacy = get(data, 'user_preferences.onboarding_privacy', false);
  const firstDiscussion = get(data, 'user_preferences.onboarding_first_discussion', false);

  const fullUser = {
    ...user,
    hasCommented: false,
    hasPersonalized: false,
    hasSecured: false
  };

  if (dismiss) return null;

  return (
    <Box>
      <Title>
        <H3 as="span">{t('GET_STARTED')}</H3>
        <Button padding="0.6em" theme="inverse" onClick={() => setExpand(current => !current)}>
          <Icon icon="chevron-up" />
        </Button>
      </Title>
      <BoxInner expand={expand}>
        {loading ? (
          <List>
            <Loader />
          </List>
        ) : (
          <List>
            <ListItems>
              <ItemWrapper>
                <IconContainer completed={user.profile_id}>
                  <Icon icon={user.profile_id ? 'check' : 'profile'} size="1.5em" />
                </IconContainer>
                <P padding="5px 0 0">Sign up to Lush Connect</P>
              </ItemWrapper>
            </ListItems>
            <ListItems>
              <ItemWrapper>
                <IconContainer completed={user.profile_image}>
                  <Icon icon={user.profile_image ? 'check' : 'upload'} size="1.5em" />
                </IconContainer>
                <P padding="5px 0 0">Add your profile picture</P>
              </ItemWrapper>
            </ListItems>
            <ListItems>
              <ItemWrapper>
                <IconContainer completed={privacy}>
                  <Icon icon={privacy ? 'check' : 'shield-security'} size="1.5em" />
                </IconContainer>
                <P padding="5px 0 0">Manage your privacy</P>
              </ItemWrapper>
            </ListItems>
            <ListItems>
              <ItemWrapper>
                <IconContainer>
                  <Icon icon={fullUser.hasPersonalized ? 'check' : 'filter-list'} size="1.5em" />
                </IconContainer>
                <P padding="5px 0 0">Personalise your feed</P>
              </ItemWrapper>
            </ListItems>
            <ListItems>
              <ItemWrapper>
                <IconContainer completed={firstDiscussion}>
                  <Icon icon={firstDiscussion ? 'check' : 'comment-edit'} size="1.5em" />
                </IconContainer>
                <P padding="5px 0 0">Post your first discussion</P>
              </ItemWrapper>
            </ListItems>
          </List>
        )}

        <Button type="button" onClick={() => setDismiss(true)}>
          {t('DISMISS_THIS_PANEL')}
        </Button>
      </BoxInner>
    </Box>
  );
};

// export
Onboarding.displayName = 'Onboarding';
export default Onboarding;
