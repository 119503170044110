import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Icon from '@lushdigital/icons';
import find from 'lodash/find';
import { buttonReset } from '@lushdigital/ui/helpers/cssHelpers';

// helpers
import routes from '../../routes';

// styles
const Nav = styled.nav`
  width: 100%;

  @media (min-width: 620px) {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  background: #000;

  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: calc(100vh - 70px);

  display: ${({ showMenu }) => (showMenu ? 'flex;' : 'none;')};

  @media (min-width: 800px) {
    display: flex;
    justify-content: center;
    height: auto;
    position: relative;
    z-index: 1;
    flex-direction: row;
  }
`;
const ListItem = styled.li`
  text-align: center;
  text-transform: uppercase;
  padding: 20px;

  @media (min-width: 800px) {
    padding: 0 20px;
  }
`;
const ListLink = styled(NavLink)`
  font-weight: 700;
  color: #ffffff;
  letter-spacing: 0;
  text-decoration: none;
  color: #8f8f8f;
  font-size: 29px;

  @media (min-width: 800px) {
    font-size: 17px;
  }

  &.is-selected {
    color: #fff;
  }
`;

const Active = styled.div`
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 20px 0;
  border-top: 1px solid #333;

  @media (min-width: 620px) {
    border-top: none;
  }

  @media (min-width: 800px) {
    display: none !important;
  }
`;
const ActiveButton = styled.button`
  ${buttonReset};
  text-decoration: none;
  padding: 0 20px;
  display: block;
  font-weight: 700;
  font-size: 17px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 800px) {
    display: none !important;
  }
`;

// exported component
const Navigation = ({ menu, setMenu }) => {
  const { t, i18n } = useTranslation();

  const { pathname } = useLocation();

  React.useEffect(() => {
    setMenu(false);
  }, [pathname, setMenu]);

  const NavItems = [
    {
      path: routes.FEED.path.replace(':language', i18n.language),
      text: t('FEED')
    },
    {
      path: routes.DISCUSSIONS.path.replace(':language', i18n.language),
      text: t('DISCUSSIONS')
    },
    {
      path: routes.COMMUNITY.path.replace(':language', i18n.language),
      text: t('COMMUNITY')
    },
    {
      path: routes.PROFILE.path.replace(':language', i18n.language),
      text: t('PROFILE')
    }
  ];

  const selectedRoute = find(NavItems, { path: pathname }) || { text: 'Menu' };

  return (
    <Nav>
      <Active>
        <ActiveButton onClick={() => setMenu(current => !current)}>
          {selectedRoute.text}
          <Icon styles={{ marginLeft: '1em' }} icon={menu ? 'caret-up' : 'caret-down'} />
        </ActiveButton>
      </Active>
      <List showMenu={menu}>
        {NavItems.map(item => {
          return (
            <ListItem key={item.path}>
              <ListLink exact activeClassName="is-selected" to={item.path}>
                {item.text}
              </ListLink>
            </ListItem>
          );
        })}
      </List>
    </Nav>
  );
};

Navigation.propTypes = {
  menu: PropTypes.bool.isRequired,
  setMenu: PropTypes.func.isRequired
};

export default Navigation;
