import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AuthWrapper } from '@lushdigital/auth';

//
import Reply from '../../components/forum/reply';
import Chatbox from '../../components/forum/chatbox';

// styled
import {
  RepliesContainer,
  RepliesWrapper,
  Title,
  Body,
  BodyInner,
  NoReplies
} from './discussion.DesktopReplies.styles';

// exported component
const Replies = ({ featured, replies, id }) => {
  const { t } = useTranslation();
  const wrapperRef = React.useRef(null);

  const scrollToBottom = () => {
    if (wrapperRef) {
      const { scrollHeight, offsetHeight } = wrapperRef.current;
      const offset = scrollHeight - offsetHeight;

      wrapperRef.current.scrollTop = offset;
    }
  };

  React.useEffect(() => scrollToBottom(), [wrapperRef]);

  return (
    <RepliesContainer>
      <RepliesWrapper>
        <Title>{featured ? t('COMMENTS') : t('REPLIES')}</Title>
        <Body ref={wrapperRef}>
          <BodyInner>
            {replies.map(reply => (
              <Reply key={reply.id} reply={reply} />
            ))}
          </BodyInner>
          <AuthWrapper authenticated>
            {replies.length === 0 && <NoReplies>{t('BE_THE_FIRST_TO_JOIN_THE_DISCUSSION')}</NoReplies>}
          </AuthWrapper>
        </Body>
        <Chatbox scrollToBottom={scrollToBottom} post={id} />
      </RepliesWrapper>
    </RepliesContainer>
  );
};

Replies.propTypes = {
  replies: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  featured: PropTypes.bool.isRequired
};

export default Replies;
