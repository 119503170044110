import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import CTA from '@lushdigital/ui/cta';

const TabButtons = styled.div`
  display: flex;
  padding: 0 5px;
  border-bottom: 1px solid #eaeaea;
`;

const Tab = styled(CTA)`
  color: #000;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  text-transform: initial;
  letter-spacing: 1px;
  padding: 20px 5px;
  margin: 0 15px;
  font-size: 14px;
  ${({ active }) =>
    active &&
    css`
      border-bottom: 2px solid #000;
      text-stroke: 1px black;
      -webkit-text-stroke: 1px black;
    `}
`;

const TabWrapper = styled.div`
  width: 100%;
  max-width: 1150px;
  margin: auto;
`;

const Tabs = ({ tabs, onTabChange }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <>
      <TabButtons>
        <TabWrapper>
          {tabs.map((tab, index) => (
            <Tab
              key={tab.title}
              onClick={() => {
                setActiveTab(index);
                onTabChange(index);
              }}
              active={activeTab === index}
              type={tab.link ? 'Link' : 'button'}
            >
              {tab.title}
            </Tab>
          ))}
        </TabWrapper>
      </TabButtons>

      <>{tabs.map((tab, index) => (activeTab === index && tab.body) || null)}</>
    </>
  );
};

Tabs.propTypes = {
  onTabChange: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.node,
      link: PropTypes.bool
    })
  ).isRequired
};

Tabs.defaultProps = {
  onTabChange: _ => _
};

export default Tabs;
