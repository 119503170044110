import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import Icon from '@lushdigital/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import find from 'lodash/find';
import get from 'lodash/get';
import has from 'lodash/has';
import { Context as AuthContext, AuthWrapper } from '@lushdigital/auth';

// ui
import SizeContext from '@lushdigital/ui/size/context';
import Container from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import H3 from '@lushdigital/ui/h3';
import P from '@lushdigital/ui/p';
import Loader from '@lushdigital/ui/loader';
import Button from '@lushdigital/ui/button';

// data
import { FORUM_LIST } from '../../apollo/apollo.queries';

//
import Error from '../../apollo/apollo.error';
import Tabs from '../../components/tabs';
import Replies from './discussions.Replies';
import ForumList from './discussions.ForumList';
import PostCreate from './discussions.PostCreate';

// styled
const Wrapper = styled.div`
  display: flex;
  position: relative;
  min-height: 100%;
`;

const CreateButton = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 8px;
  z-index: 10;

  @media (min-width: 800px) {
    font-size: 12px;
    position: absolute;
    transform: translateY(-50%);
    bottom: 100%;
    right: 30px;
    bottom: auto;
  }
`;

const Pre = styled.pre`
  display: block;
  padding: 20px;
  background-color: #eaeaea;
  overflow-x: auto;
  font-size: 14px;
  max-height: 500px;
  margin: 0 auto;
`;

const IconWrapper = styled.div`
  color: #fff;
  background: #000;
  padding: 0.5em;
  font-size: 40px;
  border-radius: 100%;
  line-height: 0;
  margin-bottom: 0.5em;
`;

// exported component
const Discussions = () => {
  const { t } = useTranslation();
  const { user } = React.useContext(AuthContext);

  const [tab, setTab] = React.useState('all');
  const history = useHistory();

  const { sizes } = React.useContext(SizeContext);
  const isLarge = find(sizes, { key: 'large' }).match;

  const [createPost, setCreatePost] = React.useState(false);
  const [selectedPost, setSelectedPost] = React.useState(null);

  const { loading, error, data } = useQuery(FORUM_LIST, {
    variables: {
      featured: false,
      user_id: tab === 'my' ? user.profile_id : null
    },
    pollInterval: 30000
  });

  const handleTabChange = activeTab => {
    setTab(activeTab === 0 ? 'all' : 'my');
    if (selectedPost) setSelectedPost(null);
  };

  React.useEffect(() => {
    const { pathname } = history.location;
    const path = selectedPost ? `${pathname}/${selectedPost}` : pathname;

    if (selectedPost && !isLarge) {
      history.push(path);
    } else if (selectedPost) {
      window.history.pushState(selectedPost, 'Discussion', path);
    }
  }, [history, isLarge, selectedPost]); // eslint-disable-line

  const isReady = !loading && !error;
  const shouldShowResults = tab === 'all' ? true : has(user, 'profile_id') && tab === 'my';
  return (
    <>
      <Tabs
        onTabChange={handleTabChange}
        tabs={[
          {
            title: t('ALL_DISCUSSIONS')
          },
          {
            title: t('MY_DISCUSSIONS')
          }
        ]}
      />

      {error && (
        <Error name="FORUM_LIST" error={error} addAlert={false}>
          {({ message, code }) => (
            <Container>
              <Row padding="80px 0" flexDirection="column">
                <Column>
                  <P>{t('WHOOPS_TITLE')}</P>
                </Column>
                <Column>
                  {process.env.NODE_ENV === 'development' && (
                    <Pre>
                      {code && (
                        <P textSize="30px" margin="0">
                          <strong>{code}</strong>
                        </P>
                      )}
                      {message && <code>{JSON.stringify(message, null, 2)}</code>}
                    </Pre>
                  )}
                </Column>
              </Row>
            </Container>
          )}
        </Error>
      )}

      {loading && (
        <Container>
          <Row padding="80px 0">
            <Column>
              <Loader />
            </Column>
          </Row>
        </Container>
      )}

      {isReady && shouldShowResults && (
        <Wrapper>
          <AuthWrapper authenticated>
            <CreateButton padding="2em" circle onClick={() => setCreatePost(true)}>
              <Icon icon="plus" />
            </CreateButton>
          </AuthWrapper>
          <ForumList
            forums={get(data, 'forum_list.forums') || []}
            selectedPost={selectedPost}
            selectPost={setSelectedPost}
          />
          {selectedPost !== null && <Replies post={selectedPost} selectPost={setSelectedPost} />}
          {createPost && <PostCreate onClose={() => setCreatePost(false)} />}
        </Wrapper>
      )}

      {isReady && !shouldShowResults && (
        <Container padding="15vmin 10px">
          <Row padding="40px 0">
            <Column alignItems="center">
              <IconWrapper>
                <Icon icon="comments" size="0.8em" />
              </IconWrapper>
              <H3 align="center" margin="0 0 10px">
                {t('YOUR_DISCUSSIONS_WILL_APPEAR_HERE')}
              </H3>
              <P align="center">{t('FIND_OUT_MORE_ABOUT_DISCUSSIONS')}</P>
            </Column>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Discussions;
