import gql from 'graphql-tag';

//
import { FORUM, USER_PREFERENCES } from './apollo.fragment';

//
export const FORUM_LIST = gql`
  query forum_list(
    $tag_ids: [String]
    $staff_only: Boolean
    $featured: Boolean
    $user_id: String
    $paginate_from: String
    $per_page: Int
    $reply_per_page: Int
    $reply_paginate_from: String
    $reply_order: String
  ) {
    forum_list(
      tag_ids: $tag_ids
      staff_only: $staff_only
      featured: $featured
      user_id: $user_id
      paginate_from: $paginate_from
      per_page: $per_page
    ) {
      forums {
        ${FORUM}
      }
    }
  }
`;

export const FORUM_POST = gql`
  query forum($id: String!, $reply_per_page: Int, $reply_paginate_from: String, $reply_order: String) {
    forum(id: $id) {
      ${FORUM}
    }
  }
`;

export const GET_USER_PREFERENCES = gql`
  query user_preferences($id: String!) {
    user_preferences(id: $id) {
      ${USER_PREFERENCES}
    }
  }
`;
