import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import Icon from '@lushdigital/icons';
import { useMutation } from '@apollo/react-hooks';
import { Context as AuthContext, AuthWrapper } from '@lushdigital/auth';

//
import Button from '@lushdigital/ui/button';

//
import Error from '../../../apollo/apollo.error';
import Toggle from '../../toggle';

//
import { FORUM_CREATE_REPLY } from '../../../apollo/apollo.mutations';
import { FORUM_POST } from '../../../apollo/apollo.queries';

//
import GROUPS from '../../../config/config.groups';

// styled
const Box = styled.div`
  flex: 0 0 auto;
  padding: 10px;
  background: #fff;
  border-top: 1px solid #eaeaea;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
`;

const Main = styled.div`
  position: relative;
  display: flex;
`;

const TextArea = styled(TextareaAutosize)`
  resize: none;
  width: 100%;
  border-top: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  border-right: none;
  padding: 0.8em 1.2em;
  font-size: 14px;
  line-height: 20px;
  -webkit-appearance: none;

  &:focus,
  &:active {
    box-shadow: inset 0 0 0 3px rgba(0, 123, 255, 0.75);
    border-color: #80bdff;
    outline: none;
  }
`;

const Submit = styled(Button)`
  border-top: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
`;

const WordCount = styled.span`
  font-size: 14px;
  color: #757575;
`;

// exported component
const Chatbox = ({ post, scrollToBottom }) => {
  const { t } = useTranslation();
  const { user } = React.useContext(AuthContext);
  const [value, setValue] = React.useState('');
  const [isStaff, setIsStaff] = React.useState(false);
  const [wordCount, setWordCount] = React.useState(200);

  const [creatReply, { error }] = useMutation(FORUM_CREATE_REPLY, {
    onCompleted: () => {
      setValue('');
      setIsStaff(false);
      scrollToBottom();
    }
  });

  React.useEffect(() => {
    if (value) {
      setWordCount(200 - value.length);
    } else {
      setWordCount(200);
    }
  }, [value]);

  React.useEffect(() => {
    setValue('');
  }, [post]);

  const submit = () => {
    creatReply({
      variables: {
        body: value,
        forum_id: post,
        official_staff: isStaff
      },

      optimisticResponse: {
        __typename: 'Mutation',
        forum_reply: {
          __typename: 'ForumReply',
          id: 'Optimistic...',
          forum_id: post,
          user_id: user.profile_id,
          created_at: Date.now(),
          body: value,
          official_staff: isStaff
        }
      },

      update: async (proxy, { data: { forum_reply: reply } }) => {
        // Read the data from our cache for this query.
        const data = proxy.readQuery({
          query: FORUM_POST,
          variables: {
            id: post,
            reply_order: 'asc'
          }
        });

        // Write our data back to the cache with the new reply in it
        await proxy.writeQuery({
          query: FORUM_POST,
          variables: {
            id: post,
            reply_order: 'asc'
          },
          data: {
            ...data,
            forum: {
              ...data.forum,
              replies: [...data.forum.replies, reply]
            }
          }
        });

        scrollToBottom();
      }
    });
  };

  if (!user.id) return null;

  return (
    <Box>
      {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}
      <Header>
        <div>
          <AuthWrapper authenticated permissions={GROUPS.CORE.ABOVE_CUSTOMER}>
            <Toggle label={t('REPLY_AS_STAFF_MEMBER')} value={isStaff} onChange={val => setIsStaff(val)} />
          </AuthWrapper>
        </div>
        <WordCount>
          {t('WORD_COUNT', {
            count: wordCount
          })}
        </WordCount>
      </Header>
      <Main>
        <TextArea
          maxLength={200}
          useCacheForDOMMeasurements
          value={value}
          onChange={e => setValue(e.target.value)}
          maxRows={5}
        />
        <Submit padding="1em" theme="inverse" onClick={() => submit()}>
          <Icon icon="mail-send" />
        </Submit>
      </Main>
    </Box>
  );
};

Chatbox.propTypes = {
  scrollToBottom: PropTypes.func.isRequired,
  post: PropTypes.string.isRequired
};

export default Chatbox;
