import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import find from 'lodash/find';

// ui
import SizeContext from '@lushdigital/ui/size/context';
import Container from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import P from '@lushdigital/ui/p';
import Loader from '@lushdigital/ui/loader';

// data
import { FORUM_POST } from '../../apollo/apollo.queries';

//
import DesktopReplies from './discussion.DesktopReplies';
import MobileReplies from './discussion.MobileReplies';
import DesktopDetails from './discussion.DesktopDetails';
import MobileDetails from './discussion.MobileDetails';
import Error from '../../apollo/apollo.error';

// styled
const DesktopWrapper = styled.div`
  position: relative;
  min-height: 100%;
  min-height: calc(100vh - 140px);
  display: flex;
  flex-direction: row;
`;

const MobileWrapper = styled.div`
  position: relative;
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Pre = styled.pre`
  padding: 20px;
  background-color: #eaeaea;
  overflow-x: auto;
  font-size: 14px;
  max-height: 500px;
`;

const HideNav = createGlobalStyle`
  .main-footer,
  .main-navigation {
    display: none !important;

    @media (min-width: 620px) {
      display: flex !important;
    }
  }
`;

// exported component
const Discussion = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { sizes } = React.useContext(SizeContext);
  const isSmall = find(sizes, { key: 'tablet' }).match;

  const { loading, error, data } = useQuery(FORUM_POST, {
    variables: {
      id,
      reply_order: 'asc'
    },
    pollInterval: 2000
  });

  if (loading) {
    return (
      <Container>
        <HideNav />
        <Row padding="80px 0">
          <Column>
            <Loader />
          </Column>
        </Row>
      </Container>
    );
  }

  if (error) {
    return (
      <Error name="FORUM_POST" error={error} addAlert={false}>
        {({ message, code }) => (
          <Container>
            <Row padding="80px 0" flexDirection="column">
              <Column>
                <P>{t('WHOOPS_TITLE')}</P>
              </Column>
              <Column>
                {process.env.NODE_ENV === 'development' && (
                  <Pre>
                    {code && (
                      <P textSize="30px" margin="0">
                        <strong>{code}</strong>
                      </P>
                    )}
                    {message && <code>{JSON.stringify(message, null, 2)}</code>}
                  </Pre>
                )}
              </Column>
            </Row>
          </Container>
        )}
      </Error>
    );
  }

  const replies = get(data, 'forum.replies') || [];

  if (isSmall) {
    return (
      <DesktopWrapper>
        <HideNav />
        <DesktopDetails
          title={get(data, 'forum.title') || ''}
          body={get(data, 'forum.body') || ''}
          link={get(data, 'forum.link') || ''}
          internal={get(data, 'forum.internal') || false}
          date={get(data, 'forum.created_at') || null}
          imageUrl={get(data, 'forum.image_url') || ''}
          imageAlt={get(data, 'forum.image_alt') || ''}
          replyCount={get(data, 'forum.reply_count') || ''}
          featured={get(data, 'forum.featured') || false}
          profileId={get(data, 'forum.user_id') || ''}
        />
        <DesktopReplies replies={replies} id={id} featured={get(data, 'forum.featured') || false} />
      </DesktopWrapper>
    );
  }
  return (
    <MobileWrapper>
      <HideNav />
      <MobileDetails
        title={get(data, 'forum.title') || ''}
        body={get(data, 'forum.body') || ''}
        link={get(data, 'forum.link') || ''}
        internal={get(data, 'forum.internal') || false}
        date={get(data, 'forum.created_at') || null}
        imageUrl={get(data, 'forum.image_url') || ''}
        imageAlt={get(data, 'forum.image_alt') || ''}
        replyCount={get(data, 'forum.reply_count') || ''}
        featured={get(data, 'forum.featured') || false}
        profileId={get(data, 'forum.user_id') || ''}
      />
      <MobileReplies replies={replies} id={id} featured={get(data, 'forum.featured') || false} />
    </MobileWrapper>
  );
};

export default Discussion;
