import React from 'react';
import styled from 'styled-components';
import { Context as AuthContext, AuthWrapper, LoginModal } from '@lushdigital/auth';
import { useTranslation } from 'react-i18next';
import Icon from '@lushdigital/icons';
import find from 'lodash/find';

// ui
import Avatar from '@lushdigital/ui/avatar';
import Button from '@lushdigital/ui/button';
import Drop from '@lushdigital/ui/drop';

// helpers
import Roles from '../../config/config.groups.json';
import routes from '../../routes';
import { languages } from '../../config/config.generated.json';

// components
import Link from '../link';
import LanguageTakeover from './header.LanguageTakeover';

// styles
const AppUserWrapper = styled.div`
  position: relative;
  margin-left: 12px;
`;

const AppUserLabel = styled.button`
  background: none;
  -webkit-appearance: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  margin: 14px 30px;
  padding: 0;
`;

const AppUserList = styled.div`
  width: 200px;
  background: #fff;
  z-index: 5;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);

  *:not(:first-child) {
    border-top: 1px solid rgb(234, 234, 234);
  }

  *:last-child {
    border: none;
  }
`;

// Exported component
const AppUser = () => {
  const { t, i18n } = useTranslation();
  const { user, logout } = React.useContext(AuthContext);
  const labelRef = React.useRef(null);

  // states
  const [login, setLogin] = React.useState(false);
  const [takeover, setTakeover] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleLoginToggle = () => {
    setLogin(current => !current);
  };

  const handleTakeoverToggle = () => {
    setTakeover(current => !current);
  };

  const handleLogout = async () => {
    await logout();
    window.location.href = `/${i18n.language}`;
  };

  const languageLabel = find(languages, { id: i18n.language });

  return (
    <AppUserWrapper ref={labelRef}>
      <AppUserLabel onClick={() => setOpen(current => !current)}>
        <Avatar user={user} />
      </AppUserLabel>
      {open && (
        <Drop fixed={false} element={labelRef.current} onClose={() => setOpen(false)}>
          <AppUserList>
            <Button theme="inverse" fullWidth onClick={() => handleTakeoverToggle()}>
              {languageLabel.name}
              <Icon icon="caret-down" size="0.85em" styles={{ marginLeft: '0.5em' }} />
            </Button>
            <AuthWrapper authenticated permissions={Roles.CORE.ALL}>
              <Button
                as={Link}
                to={routes.PROFILE.path.replace(':language', i18n.language).replace(':id', user.profile_id)}
                theme="inverse"
                fullWidth
              >
                {t('MY_PROFILE')}
              </Button>
            </AuthWrapper>
            <AuthWrapper>
              <Button fullWidth onClick={() => handleLogout()}>
                {t('SIGN_OUT')}
              </Button>
            </AuthWrapper>
            <AuthWrapper authenticated={false}>
              <Button fullWidth onClick={() => handleLoginToggle()}>
                {t('SIGN_IN')}
              </Button>
            </AuthWrapper>
          </AppUserList>
        </Drop>
      )}
      {login && <LoginModal onClose={handleLoginToggle} />}
      {takeover && <LanguageTakeover onClose={handleTakeoverToggle} />}
    </AppUserWrapper>
  );
};

// export
export default React.memo(AppUser);
