import React from 'react';
import { Login as LoginForm } from '@lushdigital/auth';
// import { ENV, appEnvironmentRoutes } from '@lushdigital/helper-functions';
// import { useTranslation } from 'react-i18next';

//
// const appRoutes = appEnvironmentRoutes(ENV);

// component
const Login = () => {
  // const { i18n } = useTranslation();

  const handleLogin = () => {
    // *** TODO: "add connect to appEnvironmentRoutes(ENV)" ***
    // const url = appRoutes.connect.replace(':language', i18n.language);
    window.location.replace('/en');
  };

  return (
    <>
      <LoginForm callback={handleLogin} />
    </>
  );
};

export default Login;
