import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AuthWrapper } from '@lushdigital/auth';

//
import Reply from '../../components/forum/reply';
import Chatbox from '../../components/forum/chatbox';

// styled
import { Body, BodyInner, NoReplies } from './discussion.MobileReplies.styles';

// exported component
const Replies = ({ replies, id }) => {
  const { t } = useTranslation();
  const wrapperRef = React.useRef(null);

  const scrollToBottom = () => {
    if (wrapperRef) {
      const { scrollHeight, offsetHeight } = wrapperRef.current;
      const offset = scrollHeight - offsetHeight;

      wrapperRef.current.scrollTop = offset;
    }
  };

  React.useEffect(() => scrollToBottom(), [wrapperRef]);

  return (
    <>
      <Body ref={wrapperRef}>
        <BodyInner>
          {replies.map(reply => (
            <Reply key={reply.id} reply={reply} />
          ))}
        </BodyInner>
        <AuthWrapper authenticated>
          {replies.length === 0 && <NoReplies>{t('BE_THE_FIRST_TO_JOIN_THE_DISCUSSION')}</NoReplies>}
        </AuthWrapper>
      </Body>
      <Chatbox scrollToBottom={scrollToBottom} post={id} />
    </>
  );
};

Replies.propTypes = {
  replies: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired
};

export default Replies;
