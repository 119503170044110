import styled from 'styled-components';
import fluidProp from '@lushdigital/ui/helpers/fluidProp';

//
import H3 from '@lushdigital/ui/h3';
import H4 from '@lushdigital/ui/h4';

// styled
export const RepliesContainer = styled.div`
  width: 50%;
  position: sticky;
  top: 70px;
  right: 0;
  height: calc(100vh - 70px);
`;

export const RepliesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Title = styled(H3)`
  padding-left: 20px;
  padding-bottom: 20px;
  text-transform: uppercase;

  ${fluidProp({
    prop: 'padding-top',
    min: { value: 20 },
    max: { value: 40 }
  })}

  ${fluidProp({
    prop: 'padding-left',
    min: { value: 20 },
    max: { value: 50 }
  })}

  ${fluidProp({
    prop: 'padding-right',
    min: { value: 20 },
    max: { value: 50 }
  })}
`;

export const Body = styled.main`
  flex: 1 0 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 20px;
  box-sizing: content-box;
  background: #f2f2f2;
  position: relative;
`;

export const BodyInner = styled.div`
  width: 100%;
`;

export const NoReplies = styled(H4)`
  font-family: 'LushHandwritten';
  position: absolute;
  bottom: 80px;
  right: 30px;
  max-width: 340px;
  width: 100%;
  padding-left: 150px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    height: 70px;
    width: 150px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTMyLjUgNTEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEzMi41IDUxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2VuYWJsZS1iYWNrZ3JvdW5kOm5ldyAgICA7fS5zdDF7Y2xpcC1wYXRoOnVybCgjU1ZHSURfMl8pO30uc3Qye2NsaXAtcGF0aDp1cmwoI1NWR0lEXzRfKTtmaWxsOm5vbmU7c3Ryb2tlOiMxRDFEMUI7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEwO308L3N0eWxlPjxnPjxnPjxnIGNsYXNzPSJzdDAiPjxnPjxkZWZzPjxyZWN0IGlkPSJTVkdJRF8xXyIgeD0iMC41IiB3aWR0aD0iMTMyIiBoZWlnaHQ9IjUxIi8+PC9kZWZzPjxjbGlwUGF0aCBpZD0iU1ZHSURfMl8iPjx1c2UgeGxpbms6aHJlZj0iI1NWR0lEXzFfIiAgc3R5bGU9Im92ZXJmbG93OnZpc2libGU7Ii8+PC9jbGlwUGF0aD48ZyBjbGFzcz0ic3QxIj48ZGVmcz48cmVjdCBpZD0iU1ZHSURfM18iIHg9IjAuNSIgd2lkdGg9IjEzMiIgaGVpZ2h0PSI1MSIvPjwvZGVmcz48Y2xpcFBhdGggaWQ9IlNWR0lEXzRfIj48dXNlIHhsaW5rOmhyZWY9IiNTVkdJRF8zXyIgIHN0eWxlPSJvdmVyZmxvdzp2aXNpYmxlOyIvPjwvY2xpcFBhdGg+PHBhdGggY2xhc3M9InN0MiIgZD0iTTI1LjEsMjRjMTMuMSw2LjYsMTMuNCw0LDguNywyLjJjLTQuNC0xLjgtMTguOC00LjctMjkuMy0yYy0xMC41LDIuNywxLjIsMy4zLDE0LjMtMjFjMi45LTUuNSwzLjYtMS4zLTMsNS4yIE0xMjUuNCwzNy41YzMuOSwxNy4xLDguNSwxNC45LDQuMiw2LjlDMTA2LjksMiw1MywxLjMsMTUuMywxN2MtMi40LDEsMy4yLDMuOCw1LjMtMiIvPjwvZz48L2c+PC9nPjwvZz48L2c+PC9zdmc+);
    background-size: 100%;
    background-repeat: no-repeat;
    transform: rotate(-45deg);
  }
`;
