import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

/**
 * Simple exmaple function to return a string
 * @param  {String} something Random string
 * @return {String}           Return the random string
 */
export const exampleHelper = something => {
  return something;
};

export function sanitize(value) {
  return value.replace(/<[^>]+>/g, '');
}

export function fromNow(date, language) {
  let formattedDate;
  try {
    formattedDate = dayjs(date)
      .locale(language)
      .fromNow();
  } catch (err) {
    console.error(err);
    formattedDate = dayjs(date).format('hh:mma - DD/MM/YYYY'); // display
  }
  return formattedDate;
}
