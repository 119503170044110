import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import InputField from '@lushdigital/ui/inputField';
import CTA from '@lushdigital/ui/cta';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import AlertsContext from '@lushdigital/ui/alerts/context';
import Alert from '@lushdigital/ui/alerts/alert';
import { Context as AuthContext } from '@lushdigital/auth';

import Error from '../../../apollo/apollo.error';
import { GET_USER_PREFERENCES } from '../../../apollo/apollo.queries';
import { UPDATE_USER_PREFERENCES } from '../../../apollo/apollo.mutations';

const ProfileForm = ({ onSubmit, userPreferences }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const { user } = React.useContext(AuthContext);
  const [firstName, setFirstName] = React.useState(userPreferences.first_name);
  const [lastName, setLastName] = React.useState(userPreferences.last_name);
  const [username, setUsername] = React.useState(userPreferences.username);
  const { insertAlert } = React.useContext(AlertsContext);

  const [updateProfile, { error }] = useMutation(UPDATE_USER_PREFERENCES, {
    refetchQueries: [{ query: GET_USER_PREFERENCES, variables: { id: user.profile_id } }],
    onError: () => setLoading(false),
    onCompleted: () => {
      setLoading(false);
      insertAlert({
        type: 'success',
        message: `🎉 ${t(`SUCCESSFULLY_UPDATED_PROFILE`)}`,
        time: 2,
        render: alertProps => <Alert key={alertProps.i} {...alertProps} />
      });
      onSubmit();
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    await updateProfile({
      variables: {
        firstName,
        lastName,
        username
      }
    });
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}
      <Row>
        <Column>
          <InputField
            label={t('FIRST_NAME')}
            placeholder={t('ENTER_YOUR_FIRST_NAME')}
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            type="text"
            name="firstName"
          />
        </Column>
        <Column>
          <InputField
            label={t('LAST_NAME')}
            placeholder={t('ENTER_YOUR_LAST_NAME')}
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            type="text"
            name="lastName"
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <InputField
            label={t('USERNAME')}
            placeholder={t('ENTER_YOUR_USERNAME')}
            value={username}
            onChange={e => setUsername(e.target.value)}
            type="text"
            name="username"
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <CTA loading={loading}>{t('SAVE_CHANGES')}</CTA>
        </Column>
      </Row>
    </form>
  );
};

ProfileForm.propTypes = {
  onSubmit: PropTypes.func,
  userPreferences: PropTypes.object.isRequired
};

ProfileForm.defaultProps = {
  onSubmit: _ => _
};

export default ProfileForm;
