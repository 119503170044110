/* eslint-disable */
// https://github.com/prettier/prettier/issues/5961
//
import styled from 'styled-components';
import P from '@lushdigital/ui/p';
import { buttonReset } from '@lushdigital/ui/helpers/cssHelpers';
import fluidProp from '@lushdigital/ui/helpers/fluidProp';

// styled
export const Page = styled.div`
  display: flex;
  width: 100%;
  background: #fff;

  @media (min-width: 620px) {
    position: static;
    justify-content: flex-end;
    width: 50%;

  }

  ${fluidProp({
    prop: 'padding-right',
    min: { value: 20 },
    max: { value: 50 }
  })}

  ${fluidProp({
    prop: 'padding-left',
    min: { value: 20 },
    max: { value: 50 }
  })}

  ${fluidProp({
    prop: 'padding-top',
    min: { value: 20 },
    max: { value: 30 }
  })}
`;

export const Wrapper = styled.div`
  position: relative;
  text-decoration: none;
  max-width: 480px;
  width: 100%;
`;

export const Main = styled.div`
  .image {
    display: block;
    margin-bottom: 20px;
  }
`;

// changes to link
export const Content = styled.div`
  text-decoration: none;
`;

export const Date = styled(P)`
  position: relative;
`;

export const ImageWrapper = styled.div`
  position: relative;
  padding: 0 !important;

  ${({ isInLink }) => isInLink ? `
    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    &:before {
      transition: all 0.3s;
      opacity: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0) 25%);
      z-index: 2;
    }

    &:after {
      transition: all 0.3s;
      opacity: 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.4) 75%);
      z-index: 2;
    }

    @media (hover: hover) {
      *:hover > & {
        &:before {
          opacity: 1;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  ` : null}
`;

export const RepliesButton = styled.button`
  ${buttonReset}
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const Title = styled(P)`
  font-weight: 700;
  padding-left: 10px;
`;

export const Header = styled.div`
  padding: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PostBy = styled.span`
  font-size: 14px;
  color: #000000;
  line-height: 21px;
  padding-bottom: 8px;
  display: block;
`