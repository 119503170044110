import React, { useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import Cookies from 'universal-cookie';
import { I18nextProvider, Translation } from 'react-i18next';
import styled, { createGlobalStyle } from 'styled-components';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { GlobalStyles, AlertProvider, AlertConsumer } from '@lushdigital/ui';
import { AuthProvider, getTokensClient } from '@lushdigital/auth';
import Icon from '@lushdigital/icons';
import Loader from '@lushdigital/ui/lushLoader';
import SizeProvider from '@lushdigital/ui/size/provider';

// Config, setup & helpers
import env from './config/config.env';
import client from './apollo/apollo.client';
import { handleLanguageRedirect } from './i18n/i18n.helpers';
import i18n from './i18n/i18n.create';

// Routes
import renderRoutes from './routes/routes.render';
import configRoutes from './routes/routes.config';

// components
import Header from './components/header';
import Footer from './components/footer';

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'HelveticaNeue';
    src: url('/assets/fonts/helvetica-neue/36C63F_0_0.eot');
    src: url('/assets/fonts/helvetica-neue/36C63F_0_0.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/helvetica-neue/36C63F_0_0.woff2') format('woff2'),
        url('/assets/fonts/helvetica-neue/36C63F_0_0.woff') format('woff'),
        url('/assets/fonts/helvetica-neue/36C63F_0_0.ttf') format('truetype');

    font-weight: 700;
    font-display: swap;
  }


  @font-face {
    font-family: 'HelveticaNeue';
    src: url('/assets/fonts/helvetica-neue/3618EF_1_0.eot');
    src: url('/assets/fonts/helvetica-neue/3618EF_1_0.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/helvetica-neue/3618EF_1_0.woff2') format('woff2'),
        url('/assets/fonts/helvetica-neue/3618EF_1_0.woff') format('woff'),
        url('/assets/fonts/helvetica-neue/3618EF_1_0.ttf') format('truetype');

    font-style: italic;
    font-weight: 700;
    font-display: swap;
  }
`;

const Page = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const Body = styled.main`
  flex: 1;
`;

//
const history = createBrowserHistory();

// Handle language redirect on route change
history.listen(location => {
  handleLanguageRedirect({
    i18n,
    location,
    replace: history.replace
  });
});

const App = () => {
  // Handle language redirect on app mount
  useEffect(() => {
    handleLanguageRedirect({
      i18n,
      location: history.location,
      replace: history.replace
    });
  }, []);

  const tokens = getTokensClient(new Cookies());
  return (
    <Page>
      <GlobalStyles />
      <Fonts />
      <ApolloProvider client={client(env.graphqlUrl)}>
        <Suspense fallback={<Loader position="absolute" />}>
          <I18nextProvider i18n={i18n}>
            <AuthProvider tokens={tokens}>
              <Router history={history}>
                <AlertProvider>
                  <AlertConsumer closeIcon={<Icon icon="close" fill="#fff" size="10px" />} />
                  <Translation>
                    {(_, { i18n: { language } }) => {
                      return (
                        <>
                          <SizeProvider>
                            <Header />
                            <Body>{renderRoutes(configRoutes, { language })}</Body>
                            <Footer />
                          </SizeProvider>
                        </>
                      );
                    }}
                  </Translation>
                </AlertProvider>
              </Router>
            </AuthProvider>
          </I18nextProvider>
        </Suspense>
      </ApolloProvider>
    </Page>
  );
};

// Render the app!
ReactDOM.render(<App />, document.getElementById('root'));
