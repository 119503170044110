import React from 'react';

// ui
import Container from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';

// Components
import Hero from './hero';
import ForumFeed from './forum-feed';

// exported component
const HomePage = () => {
  return (
    <>
      <Hero />

      <Container>
        <Row>
          <Column>
            <ForumFeed />
          </Column>
        </Row>
      </Container>
    </>
  );
};

export default HomePage;
