import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import pick from 'lodash/pick';

// bambino!
import { object, string, boolean } from 'yup';
import Form from 'bambino-form/Form';

// ui
import Button from '@lushdigital/ui/button';
import Modal from '@lushdigital/ui/modal';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import AlertsContext from '@lushdigital/ui/alerts/context';
import Alert from '@lushdigital/ui/alerts/alert';

//
import InputField from '../../../components/form/input';
import ToggleField from '../../../components/form/toggle';
import Upload from '../../../components/form/upload';

//
import Error from '../../../apollo/apollo.error';
import { FORUM_CREATE_POST, FORUM_EDIT_POST } from '../../../apollo/apollo.mutations';
import { FORUM_LIST } from '../../../apollo/apollo.queries';

// styled
const Box = styled.div`
  position: relative;
  width: 100%;
`;

const WordCount = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #757575;
`;

const InternalLink = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #757575;
`;

//
const Schema = object().shape({
  title: string().required('TITLE_REQUIRED'),
  body: string(),
  link: string().url(),
  staff_only: boolean(),
  internal_link: boolean(),
  image_url: string().required('IMAGE_REQUIRED'),
  image_alt: string()
});

// exported component
const PostCreate = ({ post, onClose }) => {
  const { t } = useTranslation();
  const [wordCount, setWordCount] = React.useState(200);
  const { insertAlert } = React.useContext(AlertsContext);

  // mutation
  const [createPost, { error }] = useMutation(post ? FORUM_EDIT_POST : FORUM_CREATE_POST, {
    onCompleted: () => {
      insertAlert({
        type: 'success',
        message: `🎉 ${t(`SUCCESSFUL_POST`)}`,
        time: 2,
        render: alertProps => <Alert key={alertProps.i} {...alertProps} />
      });
      onClose();
    }
  });

  // word count down
  const countWords = ({ target }) => {
    setWordCount(200 - target.value.length);
  };

  //
  const handleSubmit = values => {
    createPost({
      variables: {
        featured: true,
        ...values
      },

      refetchQueries: [{ query: FORUM_LIST, variables: { featured: true } }],

      update: async (proxy, { data: { forum_create: forum } }) => {
        // Read the data from our cache for this query.
        const data = proxy.readQuery({
          query: FORUM_LIST,
          variables: {
            featured: true
          }
        });

        // Write our data back to the cache with the new reply in it
        proxy.writeQuery({
          query: FORUM_LIST,
          variables: {
            featured: true
          },
          data: {
            ...data,
            forum_list: {
              ...data.forum_list,
              count: data.forum_list.count + 1,
              forums: [forum, ...data.forum_list.forums]
            }
          }
        });
      }
    });
  };

  const allowed = [
    'id',
    'featured',
    'staff_only',
    'authenticated_only',
    'link',
    'title',
    'body',
    'internal_link',
    'image_url',
    'image_alt',
    'tag_ids'
  ];
  const values = pick(post, allowed);

  return (
    <Modal onClose={() => onClose(false)} header={post ? t('EDIT_POST') : t('ADD_POST')}>
      {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}

      <Form initialValues={values} schema={Schema} onSubmit={val => handleSubmit(val)}>
        <Row>
          <Column>
            <ToggleField value={post ? post.staff_only : false} label={t('THIS_IS_FOR_STAFF_ONLY')} name="staff_only" />
          </Column>
        </Row>
        <Row>
          <Column>
            <Upload imageValue={post ? post.image_url : ''} altValue={post ? post.image_alt : ''} />
          </Column>
        </Row>
        <Row>
          <Column>
            <InputField label={t('TITLE')} placeholder={t('ENTER_TITLE')} name="title" />
          </Column>
        </Row>
        <Row>
          <Column>
            <Box>
              <WordCount>
                {t('WORD_COUNT', {
                  count: wordCount
                })}
              </WordCount>
              <InputField
                customChangeHandler={e => countWords(e)}
                label={t('POST')}
                placeholder={t('ENTER_POST')}
                name="body"
                multiline
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column>
            <Box>
              <InternalLink>
                <ToggleField
                  value={post ? post.internal_link : false}
                  label={t('INTERNAL_LINK')}
                  name="internal_link"
                />
              </InternalLink>
              <InputField label={t('URL_LINK')} placeholder={t('ENTER_URL_LINK')} name="link" />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column>
            <Button fullWidth>{t('POST_DISCUSSION')}</Button>
          </Column>
        </Row>
      </Form>
    </Modal>
  );
};

PostCreate.defaultProps = {
  post: null
};

PostCreate.propTypes = {
  onClose: PropTypes.func.isRequired,
  post: PropTypes.object
};

export default PostCreate;
