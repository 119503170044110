//
export const REPLY = `
  id
  forum_id
  user_id
  created_at
  body
  official_staff
`;

//
export const FORUM = `
  id
  parent_id
  parent_type
  created_at
  user_id
  featured
  staff_only
  authenticated_only
  link
  title
  body
  image_alt
  image_url
  internal_link
  tag_ids
  tags {
    id
    name
  }
  replies(per_page: $reply_per_page, paginate_from: $reply_paginate_from, order: $reply_order) {
    ${REPLY}
  }
  reply_count
`;

export const USER_PREFERENCES = `
  username
  first_name
  last_name
  privacy_discussion_metrics
  privacy_reply_metrics
  privacy_community
  is_private
  onboarding_privacy
  onboarding_feed
  onboarding_first_discussion
  onboarding_dismissed
  profile_image
`;
