import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useQuery } from '@apollo/react-hooks';

//
import { FORUM_USER_PUBLIC } from './username.queries';

//
import Error from '../../apollo/apollo.error';

const Loading = styled.span`
  display: inline-block;
  height: 100%;
  width: 100px;

  animation: loading-shimmer 1.8s infinite;
  background: linear-gradient(to right, transparent 4%, rgba(100, 100, 100, 0.1) 25%, transparent 34%);
  background-size: 1000px 100%;

  @keyframes loading-shimmer {
    0% {
      background-position: -500px 0;
    }
    100% {
      background-position: 500px 0;
    }
  }
`;

const Name = styled.span`
  font-weight: 700;
`;

const Opacity = styled.span`
  font-weight: 400;
`;

//
const Username = ({ profileId, showRole }) => {
  const { loading, error, data } = useQuery(FORUM_USER_PUBLIC, { variables: { id: profileId } });

  if (error) {
    return <>{error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}</>;
  }

  if (loading) {
    return <Loading>{'\u00A0'}</Loading>;
  }

  const firstName = get(data, 'forum_user_public.first_name', '');
  const lastName = get(data, 'forum_user_public.last_name', '');
  const role = get(data, 'forum_user_public.role', '');

  return (
    <Name>
      {firstName} {lastName}
      {showRole && <Opacity>{` - ${role}`}</Opacity>}
    </Name>
  );
};

Username.propTypes = {
  profileId: PropTypes.string.isRequired, // user.profile_id
  showRole: PropTypes.bool
};

Username.defaultProps = {
  showRole: false
};

export default Username;
