import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import Modal from '@lushdigital/ui/modal';
import Loader from '@lushdigital/ui/loader';
import { Context as AuthContext } from '@lushdigital/auth';

import Tabs from '../../../components/tabs';
import { GET_USER_PREFERENCES } from '../../../apollo/apollo.queries';
import ProfileForm from './profile-form';
import PasswordForm from './password-form';
import PrivacyForm from './privacy-form';

const Body = styled.div`
  padding: 25px;
`;

const FormModal = ({ onClose }) => {
  const { t } = useTranslation();
  const { user } = React.useContext(AuthContext);

  const { loading, data } = useQuery(GET_USER_PREFERENCES, { variables: { id: user.profile_id } });

  return (
    <Modal onClose={onClose} header={t('EDIT_PROFILE')} padding={false}>
      <Tabs
        tabs={[
          {
            title: t('ABOUT_YOU'),
            body: (
              <Body>
                {loading && <Loader />}
                {!loading && <ProfileForm onSubmit={onClose} userPreferences={get(data, 'user_preferences', {})} />}
              </Body>
            )
          },
          {
            title: t('PASSWORD'),
            body: (
              <Body>
                {loading && <Loader />}
                {!loading && <PasswordForm onSubmit={onClose} />}
              </Body>
            )
          },
          {
            title: t('PRIVACY'),
            body: (
              <Body>
                {loading && <Loader />}
                {!loading && <PrivacyForm onSubmit={onClose} userPreferences={get(data, 'user_preferences', {})} />}
              </Body>
            )
          }
        ]}
      />
    </Modal>
  );
};

FormModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default FormModal;
