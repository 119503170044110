import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoint, Row, H2, H4, Button, Takeover } from '@lushdigital/ui';
import { useParams } from 'react-router';
import Container from '@lushdigital/ui/container';

// helpers
import routes from '../../routes';
import { languages } from '../../config/config.generated.json';

// styled
const Wrapper = styled(Container)`
  padding: 60px 40px;

  ${breakpoint('small')`
    padding: 115px 40px;
  `}
`;

const Title = styled(H4)`
  color: #fff;
  display: block;
  margin: 0 0 30px;
`;

const LanguageTitle = styled(H2)`
  color: #fff;
  text-transform: none;
  letter-spacing: 0;
  padding: 4px 0.6em;

  ${breakpoint('small')`
    padding: 8px 0;
  `}
`;

const LanguageTakeover = ({ onClose }) => {
  const { t } = useTranslation();
  const { language } = useParams();

  const renderLanguages = () => {
    return languages.map(lang => {
      const index = routes.HOME.path.replace(':language', lang.id);

      if (language === lang.id) {
        return (
          <Button key={lang.id} theme="inverse-floating" padding="0" align="left" disabled>
            <LanguageTitle>{lang.name}</LanguageTitle>
          </Button>
        );
      }

      return (
        <Button key={lang.id} as="a" padding="0" align="left" theme="inverse-floating" href={index}>
          <LanguageTitle as="span">{lang.name}</LanguageTitle>
        </Button>
      );
    });
  };

  return (
    <Takeover onClose={onClose} headerIcon="close" headerAlign="flex-end" headerPosition="fixed">
      {() => (
        <Wrapper maxWidth="600px">
          <Title as="span">{t('SELECT_YOUR_LANGUAGE')}:</Title>
          <Row flexDirection="column">{renderLanguages()}</Row>
        </Wrapper>
      )}
    </Takeover>
  );
};

LanguageTakeover.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default LanguageTakeover;
