import React from 'react';
import useField from 'bambino-form/useField';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// local
import Row from '@lushdigital/ui/row';
import Label from '@lushdigital/ui/label';
import SubText from '@lushdigital/ui/subText';
import Input from '@lushdigital/ui/input';
import TextArea from '@lushdigital/ui/textArea';
import Error from '@lushdigital/ui/error';

// helper
import { sanitize } from '../../../helpers';

// styled
const Wrapper = styled.label`
  display: block;

  ${({ hidden }) =>
    hidden
      ? `
    visibility: hidden;
    height: 1px;
    width: 1px;
    overflow: hidden;
  `
      : `
    width: 100%;
  `};
`;

const Suffix = styled.div`
  display: flex;
  background-color: #ffffff;
  padding: 0 10px;
  font-weight: bold;
  border: 1px solid #eaeaea;
  border-left: none;
  transform: translateX(-1px);
  align-self: stretch;
  align-items: center;
  font-size: 14px;
`;

const InputField = ({
  name,
  label,
  multiline,
  schema,
  subtext,
  value,
  hidden,
  suffix,
  customChangeHandler,
  ...rest
}) => {
  const ref = React.useRef(null);
  const { t } = useTranslation();

  // sanitize

  const { error, fieldName, defaultValue, onChange, ...bag } = useField({
    name,
    ref,
    schema,
    value,
    parseValue: sanitize
  });

  const inputProps = {
    ...rest,
    ...bag,
    onChange: e => {
      e.persist();
      onChange(e);
      customChangeHandler(e);
    },
    fullWidth: true,
    defaultValue,
    ref
  };

  return (
    <Wrapper hidden={hidden} htmlFor={inputProps.name}>
      <Label textSize="14px" as="span">
        {label}
      </Label>
      {subtext !== '' && <SubText textSize="14px">{subtext}</SubText>}
      <Row flexWrap="nowrap" alignItems="stretch">
        {multiline && <TextArea {...inputProps} />}
        {!multiline && <Input {...inputProps} />}
        {suffix && <Suffix>{suffix}</Suffix>}
      </Row>
      {error && <Error margin="10px 0 0">{t(error)}</Error>}
    </Wrapper>
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  subtext: PropTypes.string,
  schema: PropTypes.any,
  hidden: PropTypes.bool,
  multiline: PropTypes.bool,
  suffix: PropTypes.string,
  customChangeHandler: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

InputField.defaultProps = {
  subtext: '',
  placeholder: '',
  schema: null,
  hidden: false,
  multiline: false,
  value: null,
  suffix: null,
  customChangeHandler: _ => _
};

InputField.displayName = 'InputField';

export default InputField;
