import React from 'react';
import { Context as AuthContext } from '@lushdigital/auth';
import styled from 'styled-components';
import shuffle from 'lodash/shuffle';
import Icon from '@lushdigital/icons';
import { Link } from 'react-router-dom';

// ui
import Container from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import H3 from '@lushdigital/ui/h3';
import P from '@lushdigital/ui/p';
import { useTranslation } from 'react-i18next';

//
import Avatar from '../../components/avatar';
import Username from '../../components/username';

//
import routes from '../../routes';

// styled
const Box = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
`;

const Title = styled(P)`
  font-weight: 700;
`;

const ImageWrapper = styled.div`
  flex: 1 0 auto;
  margin-right: 10px;
`;

const IconWrapper = styled.div`
  color: #fff;
  background: #000;
  padding: 0.5em;
  font-size: 40px;
  border-radius: 100%;
  line-height: 0;
  margin-bottom: 0.5em;
`;

//
const people = [
  'ekrd7pg5nj1vjngz',
  '9k4qnpojqq1x8yzd',
  'orv8npeyll3xl75m',
  'e4jrzp76vx3dgv8m',
  '6n27x3nqz63e8jw4',
  'mr9nqx1ry3jg8kdz',
  '506mv3mgr83q98d2',
  'k0v4n1x50wpg962o',
  'ne4jrzp7dmpdgv8m',
  'ez7wn1vwko3g20k9',
  'ekrd7pgxed3vjngz',
  'ez7wn1v4w91g20k9',
  'e4jrzp77k2pdgv8m'
];

// exported component
const Community = () => {
  const { t } = useTranslation();
  const { user } = React.useContext(AuthContext);

  if (!user.profile_id) {
    return (
      <Container padding="15vmin 10px">
        <Row padding="40px 0">
          <Column alignItems="center">
            <IconWrapper>
              <Icon icon="accounts-alt" size="1em" />
            </IconWrapper>
            <H3 align="center" margin="0 0 10px">
              {t('COMMUNITY_WILL_APPEAR_HERE')}
            </H3>
            <P align="center">{t('THIS_IS_EVERYONE_YOU_INTERACT_WITH_VIA_COMMENTS_AND_REPLIES')}</P>
          </Column>
        </Row>
      </Container>
    );
  }

  return (
    <Container padding="0 20px 8em 20px">
      <Row padding="40px 0">
        <Column>
          <P>Everyone you have interacted with via comments and replies…</P>
        </Column>
      </Row>
      <Row>
        {shuffle(people).map(person => (
          <Column flexBasis="100%" phone={6} tablet={4} key={Math.floor(Math.random() * 100)}>
            <Box to={routes.USER_PROFILE.path.replace(':profile_id', person)}>
              <ImageWrapper>
                <Avatar profileId={person} size="60" />
              </ImageWrapper>
              <Title>
                <Username profileId={person} />
              </Title>
            </Box>
          </Column>
        ))}
      </Row>
    </Container>
  );
};

export default Community;
