import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Column, CTA } from '@lushdigital/ui';
import { useTranslation } from 'react-i18next';

const Loadmore = ({ loading, next_page: nextPage, networkStatus, fetchMore }) => {
  const { t } = useTranslation();

  if (!loading && nextPage) {
    return (
      <Container>
        <Row justifyContent="center">
          <Column default={12} small={6} tablet={4}>
            <CTA
              margin="40px 0 0 0"
              fullWidth
              loading={networkStatus === 3}
              onClick={() =>
                fetchMore({
                  variables: {
                    page: nextPage
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;

                    return {
                      ...prev,
                      social_feed_posts: {
                        __typename: prev.social_feed_posts.__typename, // eslint-disable-line
                        posts: [...prev.social_feed_posts.posts, ...fetchMoreResult.social_feed_posts.posts],
                        pagination: fetchMoreResult.social_feed_posts.pagination
                      }
                    };
                  }
                })
              }
            >
              {t('LOAD_MORE')}
            </CTA>
          </Column>
        </Row>
      </Container>
    );
  }
  return null;
};

Loadmore.defaultProps = {
  next_page: null
};

Loadmore.propTypes = {
  loading: PropTypes.bool.isRequired, // ./community
  next_page: PropTypes.number, // ./community
  networkStatus: PropTypes.number.isRequired, // ./community
  fetchMore: PropTypes.func.isRequired // ./community
};

export default Loadmore;
