import React from 'react';
import PropTypes from 'prop-types';
import { Context as AuthContext } from '@lushdigital/auth';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// ui
import P from '@lushdigital/ui/p';

//
import Avatar from '../../avatar';
import Username from '../../username';
import { fromNow } from '../../../helpers';

// styled
const ReplyBox = styled.div`
  position: relative;
  white-space: pre-line;
  border-radius: 4px;
  padding: 10px;
  max-width: ${({ isSelf }) => (isSelf ? '340px' : '400px')};
  margin-left: ${({ isSelf }) => (isSelf ? 'auto' : '40px')};

  background: ${({ isSelf, official }) => {
    if (official) return '#00a44c';
    if (isSelf) return '#000';
    return '#fff';
  }};

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const Text = styled(P)`
  color: ${({ isSelf, official }) => {
    if (official || isSelf) return '#fff';
    return '#000';
  }};
`;

const UserName = styled(P)`
  font-weight: 700;
`;

const User = styled.div`
  position: absolute;
  top: 0;
  left: -40px;
`;

const Date = styled(P)`
  color: ${({ official }) => {
    if (official) return '#fff';
    return '#757575';
  }};
`;

// exported component
const Reply = ({ reply }) => {
  const { t, i18n } = useTranslation();
  const { user } = React.useContext(AuthContext);

  const isSelf = reply.user_id === user.profile_id;
  const official = reply.official_staff;

  return (
    <ReplyBox isSelf={isSelf} official={official}>
      {!isSelf && (
        <>
          <User>
            <Avatar textSize="30px" profileId={reply.user_id} />
          </User>
          <UserName color={official ? '#fff' : '#000'} textSize="14px" lineHeight="17px" margin="0 0 8px">
            <Username profileId={reply.user_id} showRole={official} />
          </UserName>
        </>
      )}
      {isSelf && official && (
        <UserName color={official ? '#fff' : '#000'} textSize="14px" lineHeight="17px" margin="0 0 8px">
          {t('YOU_AS_STAFF_MEMBER')}
        </UserName>
      )}
      <Text isSelf={isSelf} official={official} margin="0 0 15px" lineHeight="20px" textSize="14px">
        {reply.body}
      </Text>
      <Date official={official} textSize="11px" lineHeight="12px">
        {fromNow(reply.created_at, i18n.language)}
      </Date>
    </ReplyBox>
  );
};

Reply.propTypes = {
  reply: PropTypes.object.isRequired
};

export default Reply;
