import React from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Context as AuthContext } from '@lushdigital/auth';
import Container from '@lushdigital/ui/container';
import H2 from '@lushdigital/ui/h2';
import H4 from '@lushdigital/ui/h4';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import H3 from '@lushdigital/ui/h3';
import P from '@lushdigital/ui/p';
import Button from '@lushdigital/ui/button';
import Icon from '@lushdigital/icons';
import Loader from '@lushdigital/ui/loader';

import { GET_USER_PREFERENCES } from '../../apollo/apollo.queries';
import routes from '../../routes';
//
import FormModal from './form-modal';
import Onboarding from './profile.Onboarding';
import Avatar from '../../components/avatar';

//
const Wrapper = styled(Container)`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 720px) {
    flex-wrap: nowrap;
  }
`;

const ProfileBlock = styled.div`
  padding: 40px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    padding-top: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }

  > * {
    text-align: center;
  }
`;

const Stat = styled(Column)`
  text-align: center;
  align-items: center;
  flex: 0 1 225px;
  > * {
    text-align: center;
  }
`;

const Username = styled.p`
  opacity: 0.8;
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 0;
`;

const SubHeading = styled(H4)`
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const AbsoluteTopRight = styled.div`
  position: absolute;
  top: 40px;
  right: 20px;
`;

const IconButton = styled(Button)`
  height: 60px;
  width: 60px;
  padding: 0;
  font-size: 20px;
`;

const CommunityItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 50%;
  margin: 0 5px;
  width: 60px;
  height: 60px;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
`;

const IconWrapper = styled.div`
  color: #fff;
  background: #000;
  padding: 0.5em;
  font-size: 40px;
  border-radius: 100%;
  line-height: 0;
  margin-bottom: 0.5em;
`;

const people = [
  {
    image: 'https://res.cloudinary.com/ddal9rb1d/image/upload/v1574351037/connect/JigglyPaul.png',
    name: 'Jiggly Paul'
  },
  {
    image: 'https://res.cloudinary.com/ddal9rb1d/image/upload/v1574351037/connect/FrankieReeves.png',
    name: 'Frankie Reeves'
  },
  {
    image: 'https://res.cloudinary.com/ddal9rb1d/image/upload/v1574351037/connect/JackConstantine.png',
    name: 'Jack Constantine'
  }
];

const ProfilePage = () => {
  const [showModal, setShowModal] = React.useState(false);
  const { t, i18n } = useTranslation();
  const { profile_id: profileId } = useParams();
  const { user } = React.useContext(AuthContext);

  const { loading, data } = useQuery(GET_USER_PREFERENCES, {
    variables: { id: profileId || user.profile_id }
  });

  if (!loading && get(data, 'user_preferences') === null) {
    return (
      <Container padding="15vmin 10px">
        <Row padding="40px 0">
          <Column alignItems="center">
            <IconWrapper>
              <Icon icon="lock" size="1em" />
            </IconWrapper>
            <H3 align="center" margin="0 0 10px">
              {t('PROFILE_IS_PRIVATE')}
            </H3>
          </Column>
        </Row>
      </Container>
    );
  }

  const userPreferences = get(data, 'user_preferences') || {};
  const currentUser = profileId === user.profile_id || !profileId;
  const displayMetrics =
    currentUser || userPreferences.privacy_discussion_metrics || userPreferences.privacy_reply_metrics;

  return (
    <Wrapper>
      <Helmet>
        <title>{`${get(userPreferences, 'first_name', '')} ${get(userPreferences, 'last_name', '')}`}</title>
      </Helmet>

      {showModal && <FormModal onClose={() => setShowModal(false)} />}

      {currentUser && <Onboarding />}
      <Container maxWidth="700px">
        {user.id && currentUser && (
          <AbsoluteTopRight>
            <IconButton title="edit" circle onClick={() => setShowModal(true)}>
              <Icon icon="edit" />
            </IconButton>
          </AbsoluteTopRight>
        )}

        <Row padding="50px 0">
          <Column justifyContent="center">
            {!user.id && (
              <Container padding="15vmin 10px">
                <Row padding="40px 0">
                  <Column alignItems="center">
                    <IconWrapper>
                      <Icon icon="profile" size="0.8em" />
                    </IconWrapper>
                    <H3 align="center" margin="0 0 10px">
                      {t('YOUR_PROFILE_WILL_APPEAR_HERE')}
                    </H3>
                    <P align="center">{t('FIND_OUT_MORE_ABOUT_YOUR_PROFILE')}</P>
                  </Column>
                </Row>
              </Container>
            )}

            {user.id && (
              <Column alignItems="center">
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    <ProfileBlock>
                      <Avatar
                        profileId={currentUser ? user.profile_id : profileId}
                        circleSize="3.2em"
                        letterSize="1.3em"
                        user={userPreferences}
                      />
                      <H2 margin="20px 0 10px 0" align="center">
                        {userPreferences.first_name} {userPreferences.last_name}
                      </H2>
                      {userPreferences.username && <Username>{userPreferences.username}</Username>}
                    </ProfileBlock>

                    {displayMetrics && (
                      <ProfileBlock>
                        <SubHeading align="center">{t('MY_ACTIVITY')}</SubHeading>
                        <Row justifyContent="center">
                          {(currentUser || userPreferences.privacy_discussion_metrics) && (
                            <Stat>
                              <H2>{Math.floor(Math.random() * 100)}</H2>
                              <P>{t('DISCUSSIONS_POSTED')}</P>
                            </Stat>
                          )}
                          {(currentUser || userPreferences.privacy_reply_metrics) && (
                            <Stat>
                              <H2>{Math.floor(Math.random() * 100)}</H2>
                              <P>{t('REPLIES_TO_THREADS')}</P>
                            </Stat>
                          )}
                        </Row>
                      </ProfileBlock>
                    )}

                    {(currentUser || userPreferences.privacy_community) && (
                      <ProfileBlock>
                        <SubHeading align="center">{t('MY_COMMUNITY')}</SubHeading>
                        <Row justifyContent="center">
                          {people.map(person => (
                            <Column padding="0" flexBasis="0" flexGrow="0" key={person.name}>
                              <CommunityItem>
                                <img src={person.image} alt={person.name} />
                              </CommunityItem>
                            </Column>
                          ))}
                          <Column padding="0" flexBasis="0" flexGrow="0">
                            <Link
                              to={routes.COMMUNITY.path.replace(':language', i18n.language)}
                              style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                              <CommunityItem>
                                <p>10+</p>
                              </CommunityItem>
                            </Link>
                          </Column>
                        </Row>
                      </ProfileBlock>
                    )}
                  </>
                )}
              </Column>
            )}
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default ProfilePage;
