import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import InputField from '@lushdigital/ui/inputField';
import CTA from '@lushdigital/ui/cta';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import AlertsContext from '@lushdigital/ui/alerts/context';
import Alert from '@lushdigital/ui/alerts/alert';
import { Context as AuthContext } from '@lushdigital/auth';

import Error from '../../../apollo/apollo.error';
import { GET_USER_PREFERENCES } from '../../../apollo/apollo.queries';
import { UPDATE_USER_PREFERENCES } from '../../../apollo/apollo.mutations';

const PasswordForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { user } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
  const [passwordMatchError, setPasswordMatchError] = React.useState(false);
  const { insertAlert } = React.useContext(AlertsContext);

  const [updateProfile, { error }] = useMutation(UPDATE_USER_PREFERENCES, {
    refetchQueries: [{ query: GET_USER_PREFERENCES, variables: { id: user.profile_id } }],
    onError: () => setLoading(false),
    onCompleted: () => {
      setLoading(false);
      insertAlert({
        type: 'success',
        message: `🎉 ${t(`SUCCESSFULLY_UPDATED_PROFILE`)}`,
        time: 2,
        render: alertProps => <Alert key={alertProps.i} {...alertProps} />
      });
      onSubmit();
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    if (newPassword !== confirmNewPassword) {
      setPasswordMatchError(true);
      return;
    }
    setPasswordMatchError(false);
    await updateProfile({
      variables: {
        password: newPassword
      }
    });
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}
      <Row>
        <Column>
          <InputField
            label={t('EXISTING_PASSWORD')}
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder={t('ENTER_PASSWORD')}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <InputField
            label={t('CREATE_NEW_PASSWORD')}
            type="password"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            placeholder={t('ENTER_NEW_PASSWORD')}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <InputField
            label={t('CONFIRM_NEW_PASSWORD')}
            type="password"
            value={confirmNewPassword}
            onChange={e => setConfirmNewPassword(e.target.value)}
            error={t('PASSWORD_MATCH_ERROR')}
            showError={passwordMatchError}
            placeholder={t('COMFIRM_NEW_PASSWORD')}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <CTA loading={loading}>{t('SAVE_CHANGES')}</CTA>
        </Column>
      </Row>
    </form>
  );
};

PasswordForm.propTypes = {
  onSubmit: PropTypes.func
};

PasswordForm.defaultProps = {
  onSubmit: _ => _
};

export default PasswordForm;
