/* eslint-disable no-mixed-operators */
import { colour, Label } from '@lushdigital/ui';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const trackWidth = 40;
const circleWidth = 20;

const Track = styled.div`
  border-radius: 50px;
  height: 14px;
  z-index: 1;
  width: ${trackWidth}px;
  position: relative;
  background: ${({ disabled, isActive }) => {
    if (disabled) return colour.lightgray;
    if (!isActive) return colour.darkgray40;
    return colour.green40;
  }};
  cursor: pointer;
  transition: background 150ms ease;

  ${({ orientation }) =>
    orientation === 'vertical' &&
    css`
      transform: rotate(-90deg);
    `}
`;

const Circle = styled.div`
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
  min-width: 0;
  min-height: 0;
  border-radius: 50%;
  width: ${circleWidth}px;
  height: 20px;
  padding: 0;
  position: absolute;
  background: ${({ isActive }) => (isActive ? colour.green : colour.darkgray)};
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  z-index: 2;
  left: ${({ isActive }) => (isActive ? `${trackWidth - 0.5 - circleWidth / 2}px` : `${0.5 + circleWidth / 2}px`)};
  transition: left 250ms ease;
`;

const HiddenInput = styled.input`
  z-index: 2;
  border: 0;
  background: transparent;
  visibility: hidden;
  width: 2.4rem;
  height: 1rem;
  display: inline-block;
`;

const StyledLabel = styled(Label)`
  display: inline-block;
  margin: 0 0 0 16px;

  ${({ orientation }) =>
    orientation === 'vertical' &&
    css`
      margin: 12px 0 0 0;
    `}
`;

const Wrapper = styled.div`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;

  ${({ orientation }) =>
    orientation === 'vertical' &&
    css`
      flex-direction: column;
      justify-content: center;
    `}

  @media print {
    display: none;
  }
`;

const ToggleButton = ({ disabled, orientation, label, className, isActive, onChange }) => {
  const [active, setActive] = React.useState(isActive);

  const handleButtonClicked = e => {
    if (disabled) return;

    onChange(!active, e);
    setActive(!active);
  };

  return (
    <Wrapper orientation={orientation} className={className}>
      <Track orientation={orientation} isActive={active} onClick={handleButtonClicked} disabled={disabled}>
        <Circle isActive={active} />
        <HiddenInput type="checkbox" />
      </Track>
      {label && (
        <StyledLabel onClick={handleButtonClicked} orientation={orientation}>
          {label}
        </StyledLabel>
      )}
    </Wrapper>
  );
};

ToggleButton.propTypes = {
  onChange: PropTypes.func,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  label: PropTypes.string,
  className: PropTypes.string
};

ToggleButton.defaultProps = {
  onChange: isActive => isActive,
  isActive: undefined,
  disabled: false,
  orientation: 'horizontal',
  label: '',
  className: ''
};

export default ToggleButton;
