/* eslint-disable */
// https://github.com/prettier/prettier/issues/5961
//
import styled from 'styled-components';
import H3 from '@lushdigital/ui/h3';
import P from '@lushdigital/ui/p';
import Button from '@lushdigital/ui/button';
import { buttonReset } from '@lushdigital/ui/helpers/cssHelpers';
import fluidProp from '@lushdigital/ui/helpers/fluidProp';

// styled
export const Page = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
`;

export const Wrapper = styled.div`
  position: relative;
  text-decoration: none;
  width: 100%;
`;

// ====== //
// HEADER //
// ====== //
export const Header = styled.div`
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #EAEAEA;

  .image {
    display: block;
    width: 50px;
    margin-right: 20px;
  }
`;

export const Title = styled(H3)`
  font-weight: 700;
  padding-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ExpanderButton = styled(Button)`
  transform: rotate(${ ({ expand }) => (expand ? '0deg' : '180deg') });
  transition: transform 0.3s !important;
`;

// ==== //
// BODY //
// ==== //
export const Main = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background: #fff;
  overflow: hidden;
  max-height: ${({ expand }) => (expand ? '800px' : '0')};
  transition: 0.3s all;
  width: 100%;

  .image {
    display: none;
  }
`;

// used to convert to a link
export const Content = styled.div`
  padding: 14px 20px;
  text-decoration: none;
  border-bottom: 1px solid #EAEAEA;
`;

export const PostBy = styled.span`
  font-size: 14px;
  color: #000000;
  line-height: 21px;
  padding-bottom: 8px;
  display: block;
`

export const Date = styled(P)`
  position: relative;
`;

export const ImageWrapper = styled.div`
  position: relative;
  padding: 0 !important;

  ${({ isInLink }) => isInLink ? `
    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    &:before {
      transition: all 0.3s;
      opacity: 0;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0) 25%);
      z-index: 2;
    }

    &:after {
      transition: all 0.3s;
      opacity: 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.4) 75%);
      z-index: 2;
    }

    @media (hover: hover) {
      *:hover > & {
        &:before {
          opacity: 1;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  ` : null}
`;

