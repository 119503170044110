import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import fluidProp from '@lushdigital/ui/helpers/fluidProp';
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import Icon from '@lushdigital/icons';
import { AuthWrapper } from '@lushdigital/auth';

// ui
import Button from '@lushdigital/ui/button';
import Container from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import Loader from '@lushdigital/ui/loader';
import H3 from '@lushdigital/ui/h3';
import H4 from '@lushdigital/ui/h4';

//
import Reply from '../../components/forum/reply';
import Chatbox from '../../components/forum/chatbox';

//
import { FORUM_POST } from '../../apollo/apollo.queries';

// styled
const RepliesContainer = styled.div`
  background: #f2f2f2;
  width: 50%;
  position: sticky;
  top: 70px;
  right: 0;
  z-index: 15;

  /* page height - navigation height - tab height */
  margin-top: -65px;
  height: calc(100vh - 70px);
  border-left: 1px solid #eaeaea;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 30px;
  right: 30px;
`;

const RepliesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

const Title = styled(H3)`
  padding-left: 20px;
  padding-bottom: 20px;
  text-transform: uppercase;

  ${fluidProp({
    prop: 'padding-top',
    min: { value: 20 },
    max: { value: 50 }
  })}

  ${fluidProp({
    prop: 'padding-left',
    min: { value: 20 },
    max: { value: 50 }
  })}
`;

const Body = styled.main`
  flex: 1 0 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 20px;
  box-sizing: content-box;
  position: relative;

  ${fluidProp({
    prop: 'padding-left',
    min: { value: 20 },
    max: { value: 50 }
  })}
`;

const BodyInner = styled.div`
  max-width: 480px;
  width: 100%;
`;

const NoReplies = styled(H4)`
  font-family: 'LushHandwritten';
  position: absolute;
  bottom: 50px;
  right: 40px;
  max-width: 280px;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 5px;
    height: 70px;
    width: 150px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTMyLjUgNTEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEzMi41IDUxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2VuYWJsZS1iYWNrZ3JvdW5kOm5ldyAgICA7fS5zdDF7Y2xpcC1wYXRoOnVybCgjU1ZHSURfMl8pO30uc3Qye2NsaXAtcGF0aDp1cmwoI1NWR0lEXzRfKTtmaWxsOm5vbmU7c3Ryb2tlOiMxRDFEMUI7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjEwO308L3N0eWxlPjxnPjxnPjxnIGNsYXNzPSJzdDAiPjxnPjxkZWZzPjxyZWN0IGlkPSJTVkdJRF8xXyIgeD0iMC41IiB3aWR0aD0iMTMyIiBoZWlnaHQ9IjUxIi8+PC9kZWZzPjxjbGlwUGF0aCBpZD0iU1ZHSURfMl8iPjx1c2UgeGxpbms6aHJlZj0iI1NWR0lEXzFfIiAgc3R5bGU9Im92ZXJmbG93OnZpc2libGU7Ii8+PC9jbGlwUGF0aD48ZyBjbGFzcz0ic3QxIj48ZGVmcz48cmVjdCBpZD0iU1ZHSURfM18iIHg9IjAuNSIgd2lkdGg9IjEzMiIgaGVpZ2h0PSI1MSIvPjwvZGVmcz48Y2xpcFBhdGggaWQ9IlNWR0lEXzRfIj48dXNlIHhsaW5rOmhyZWY9IiNTVkdJRF8zXyIgIHN0eWxlPSJvdmVyZmxvdzp2aXNpYmxlOyIvPjwvY2xpcFBhdGg+PHBhdGggY2xhc3M9InN0MiIgZD0iTTI1LjEsMjRjMTMuMSw2LjYsMTMuNCw0LDguNywyLjJjLTQuNC0xLjgtMTguOC00LjctMjkuMy0yYy0xMC41LDIuNywxLjIsMy4zLDE0LjMtMjFjMi45LTUuNSwzLjYtMS4zLTMsNS4yIE0xMjUuNCwzNy41YzMuOSwxNy4xLDguNSwxNC45LDQuMiw2LjlDMTA2LjksMiw1MywxLjMsMTUuMywxN2MtMi40LDEsMy4yLDMuOCw1LjMtMiIvPjwvZz48L2c+PC9nPjwvZz48L2c+PC9zdmc+);
    background-size: 100%;
    background-repeat: no-repeat;
    transform: rotate(-45deg);
  }
`;

// exported component
const Replies = ({ post, selectPost }) => {
  const { t } = useTranslation();

  const wrapperRef = React.useRef(null);
  const { loading, error, data } = useQuery(FORUM_POST, {
    variables: {
      id: post,
      reply_order: 'asc'
    },
    pollInterval: 2000
  });

  const replies = get(data, 'forum.replies') || [];

  const scrollToBottom = () => {
    if (wrapperRef) {
      const { scrollHeight, offsetHeight } = wrapperRef.current;
      const offset = scrollHeight - offsetHeight;

      wrapperRef.current.scrollTop = offset;
    }
  };

  React.useEffect(() => scrollToBottom(), [wrapperRef, post, loading]);

  if (error) return `Error! ${error.message}`;

  return (
    <RepliesContainer>
      <CloseButton theme="inverse" padding="1.2em" circle onClick={() => selectPost(null)}>
        <Icon icon="close" />
      </CloseButton>
      <RepliesWrapper>
        <Title>{t('REPLIES')}</Title>
        <Body ref={wrapperRef}>
          <BodyInner>
            {loading && (
              <Container>
                <Row padding="80px 0">
                  <Column>
                    <Loader />
                  </Column>
                </Row>
              </Container>
            )}
            {!loading && replies.map(reply => <Reply key={reply.id} reply={reply} />)}
            <AuthWrapper authenticated>
              {!loading && replies.length === 0 && <NoReplies>{t('BE_THE_FIRST_TO_JOIN_THE_DISCUSSION')}</NoReplies>}
            </AuthWrapper>
          </BodyInner>
        </Body>
        <Chatbox scrollToBottom={scrollToBottom} post={post} />
      </RepliesWrapper>
    </RepliesContainer>
  );
};

Replies.propTypes = {
  post: PropTypes.string.isRequired,
  selectPost: PropTypes.func.isRequired
};

export default Replies;
