import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';

// bambino!
import { object, string, boolean } from 'yup';
import Form from 'bambino-form/Form';

// ui
import Button from '@lushdigital/ui/button';
import Modal from '@lushdigital/ui/modal';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import AlertsContext from '@lushdigital/ui/alerts/context';
import Alert from '@lushdigital/ui/alerts/alert';

//
import { FORUM_CREATE_POST } from '../../apollo/apollo.mutations';

//
import Error from '../../apollo/apollo.error';
import InputField from '../../components/form/input';
import ToggleField from '../../components/form/toggle';

// styled
const Box = styled.div`
  position: relative;
  width: 100%;
`;

const WordCount = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #757575;
`;

//
const Schema = object().shape({
  title: string().required('TITLE_REQUIRED'),
  body: string(),
  staff_only: boolean()
});

// exported component
const PostCreate = ({ onClose }) => {
  const { t } = useTranslation();
  const [wordCount, setWordCount] = React.useState(200);
  const { insertAlert } = React.useContext(AlertsContext);

  // mutation
  const [createPost, { error }] = useMutation(FORUM_CREATE_POST, {
    onCompleted: () => {
      insertAlert({
        type: 'success',
        message: `🎉 ${t(`SUCCESSFUL_POST`)}`,
        time: 2,
        render: alertProps => <Alert key={alertProps.i} {...alertProps} />
      });
      onClose();
    }
  });

  // word count down
  const countWords = ({ target }) => {
    setWordCount(200 - target.value.length);
  };

  //
  const handleSubmit = values => {
    createPost({
      variables: {
        ...values
      }
    });
  };

  return (
    <Modal onClose={() => onClose(false)} header={t('NEW_DISCUSSION')}>
      {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}

      <Form schema={Schema} onSubmit={val => handleSubmit(val)}>
        <Row>
          <Column>
            <ToggleField label={t('THIS_IS_FOR_STAFF_ONLY')} name="staff_only" />
          </Column>
        </Row>
        <Row>
          <Column>
            <InputField label={t('TITLE')} placeholder={t('ENTER_TITLE')} name="title" />
          </Column>
        </Row>
        <Row>
          <Column>
            <Box>
              <WordCount>
                {t('WORD_COUNT', {
                  count: wordCount
                })}
              </WordCount>
              <InputField
                customChangeHandler={e => countWords(e)}
                label={t('POST')}
                placeholder={t('ENTER_POST')}
                name="body"
                multiline
              />
            </Box>
          </Column>
        </Row>
        <Row>
          <Column>
            <Button fullWidth>{t('POST_DISCUSSION')}</Button>
          </Column>
        </Row>
      </Form>
    </Modal>
  );
};

PostCreate.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default PostCreate;
