import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

//
import Avatar from '@lushdigital/ui/avatar';

//
import { FORUM_USER_PUBLIC } from './avatar.queries';

//
import Error from '../../apollo/apollo.error';
import routes from '../../routes';

//
const LinkWrapper = styled(Link)`
  text-decoration: none;
`;

//
const AvatarComponent = ({ profileId, ...props }) => {
  const { i18n } = useTranslation();
  const { loading, error, data } = useQuery(FORUM_USER_PUBLIC, { variables: { id: profileId } });

  const profileLink = routes.USER_PROFILE.path.replace(':language', i18n.language).replace(':profile_id', profileId);

  if (loading || error) {
    return (
      <LinkWrapper to={profileLink}>
        {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}
        <Avatar user={{}} {...props} />
      </LinkWrapper>
    );
  }

  const profile = data.forum_user_public;

  return (
    <LinkWrapper to={profileLink}>
      <Avatar user={profile} {...props} />
    </LinkWrapper>
  );
};

AvatarComponent.propTypes = {
  profileId: PropTypes.string.isRequired // user.profile_id
};

export default AvatarComponent;
